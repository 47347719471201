import { Notification } from '../types';
import { collection, addDoc, getDocs, getDoc, deleteDoc, doc, updateDoc, query, where, orderBy, writeBatch } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';

export const notificationsService = {
  // Migrar notificações antigas
  async migrateOldNotifications(): Promise<void> {
    const querySnapshot = await getDocs(collection(db, 'notifications'));
    const batch = writeBatch(db);
    let requiresMigration = false;
    
    // Primeiro, coletamos todas as notificações que precisam de ordem
    const notifications = querySnapshot.docs.map((doc, index) => {
      const data = doc.data();
      if (typeof data.order !== 'number') {
        requiresMigration = true;
        return { id: doc.id, ...data, order: index };
      }
      return null;
    }).filter(Boolean);

    if (requiresMigration) {
      // Atualiza as notificações que não têm ordem
      notifications.forEach((notification) => {
        if (notification) {
          const notificationRef = doc(db, 'notifications', notification.id);
          batch.update(notificationRef, { order: notification.order });
        }
      });

      await batch.commit();
    }
  },

  // Listar todas as notificações
  async list(): Promise<Notification[]> {
    await this.migrateOldNotifications(); // Garante que todas as notificações têm ordem
    const querySnapshot = await getDocs(query(collection(db, 'notifications'), orderBy('order', 'asc')));
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Notification[];
  },

  // Criar nova notificação
  async create(formData: FormData): Promise<Notification> {
    const title = formData.get('title') as string;
    const destinationUrl = formData.get('destinationUrl') as string;
    const imageFile = formData.get('imageFile') as File | null;

    let thumbnailUrl = null;

    if (imageFile) {
      const fileRef = ref(storage, `notifications/${Date.now()}-${imageFile.name}`);
      await uploadBytes(fileRef, imageFile);
      thumbnailUrl = await getDownloadURL(fileRef);
    }

    // Buscar a última ordem
    const notifications = await this.list();
    const order = notifications.length;

    const notificationData: Record<string, any> = {
      title,
      isActive: true,
      order,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    // Adiciona campos opcionais apenas se tiverem valor
    if (destinationUrl) {
      notificationData.destinationUrl = destinationUrl;
    }
    if (thumbnailUrl) {
      notificationData.thumbnailUrl = thumbnailUrl;
    }

    const docRef = await addDoc(collection(db, 'notifications'), notificationData);
    return {
      id: docRef.id,
      ...notificationData
    } as Notification;
  },

  // Atualizar notificação
  async update(id: string, data: Partial<Notification>): Promise<Notification> {
    const docRef = doc(db, 'notifications', id);
    const updateData: Record<string, any> = {
      ...data,
      updatedAt: new Date()
    };

    // Remove campos undefined
    Object.keys(updateData).forEach(key => {
      if (updateData[key] === undefined) {
        delete updateData[key];
      }
    });

    await updateDoc(docRef, updateData);
    return {
      id,
      ...data
    } as Notification;
  },

  // Deletar notificação
  async delete(id: string): Promise<void> {
    await deleteDoc(doc(db, 'notifications', id));
  },

  // Toggle notificação
  async toggle(id: string): Promise<Notification> {
    const docRef = doc(db, 'notifications', id);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Notificação não encontrada');
    }

    const currentData = docSnap.data();
    
    await updateDoc(docRef, {
      isActive: !currentData.isActive,
      updatedAt: new Date()
    });

    return {
      id,
      ...currentData,
      isActive: !currentData.isActive
    } as Notification;
  },

  // Atualizar ordem das notificações
  async updateOrder(notifications: Notification[]): Promise<void> {
    const batch = writeBatch(db);
    
    notifications.forEach((notification, index) => {
      const notificationRef = doc(db, 'notifications', notification.id!);
      batch.update(notificationRef, { order: index });
    });

    await batch.commit();
  }
}; 