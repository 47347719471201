import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
  useTheme,
  Alert,
  Snackbar,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  TrendingUp,
  Notifications,
  Category,
  PlayArrow,
  Info,
  Refresh,
  Star,
  AccessTime,
  People,
  Timeline,
  MonetizationOn,
} from '@mui/icons-material';
import { analyticsService, DetailedMetrics, CategoryMetrics, ContentMetrics, UserMetrics } from '../../services';
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`analytics-tabpanel-${index}`}
      aria-labelledby={`analytics-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

interface CategoryEntry {
  id: string;
  name: string;
  views: number;
  uniqueViews: number;
  retentionRate: number;
  averageTimeSpent: number;
  conversionRate: number;
  revenue: number;
}

interface ContentEntry {
  id: string;
  title: string;
  views: number;
  uniqueViews: number;
  averageWatchTime: number;
  completionRate: number;
  retentionRate: number;
}

interface UserEntry {
  id: string;
  totalTimeSpent: number;
  lastActive: Date;
  sessionCount: number;
  categoriesViewed: string[];
  contentsViewed: string[];
}

const Analytics: React.FC = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [metrics, setMetrics] = useState<DetailedMetrics | null>(null);

  const fetchMetrics = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await analyticsService.getDetailedMetrics();
      console.log('Métricas carregadas:', data); // Debug
      setMetrics(data);
    } catch (error) {
      console.error('Erro ao carregar métricas:', error);
      setError('Erro ao carregar métricas. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const MetricCard = ({ title, value, icon, color, subtitle }: { 
    title: string; 
    value: number | string; 
    icon: React.ReactNode; 
    color: string;
    subtitle?: string;
  }) => (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{ 
            p: 1, 
            borderRadius: 1, 
            bgcolor: `${color}15`,
            color: color,
            mr: 2 
          }}>
            {icon}
          </Box>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" component="div" sx={{ color: color }}>
          {value}
        </Typography>
        {subtitle && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {subtitle}
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  const formatDuration = (minutes: number | null | undefined): string => {
    if (minutes == null) return '0min';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    return hours > 0 
      ? `${hours}h ${remainingMinutes}min`
      : `${remainingMinutes}min`;
  };

  const formatPercentage = (value: number | null | undefined): string => {
    if (value == null) return '0%';
    return `${value.toFixed(2)}%`;
  };

  const formatCurrency = (value: number | null | undefined): string => {
    if (value == null) return 'R$ 0,00';
    return `R$ ${value.toFixed(2)}`;
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1">
          Analytics
        </Typography>
        <IconButton onClick={fetchMetrics} disabled={loading}>
          <Refresh />
        </IconButton>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : !metrics ? (
        <Alert severity="info" sx={{ mt: 2 }}>
          Nenhum dado disponível. Aguarde a coleta de métricas.
        </Alert>
      ) : (
        <>
          {/* Visão Geral */}
          <Typography variant="h5" sx={{ mb: 3 }}>
            Visão Geral
          </Typography>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={3}>
              <MetricCard
                title="Usuários Ativos"
                value={metrics.users.active}
                icon={<People />}
                color={theme.palette.primary.main}
                subtitle={`Total: ${metrics.users.total}`}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MetricCard
                title="Tempo Médio de Sessão"
                value={formatDuration(metrics.users.averageSessionTime)}
                icon={<AccessTime />}
                color={theme.palette.success.main}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MetricCard
                title="Taxa de Retenção"
                value={formatPercentage(metrics.users.retentionRate)}
                icon={<Timeline />}
                color={theme.palette.info.main}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <MetricCard
                title="Receita Total"
                value={formatCurrency(metrics.conversions.revenueTotal)}
                icon={<MonetizationOn />}
                color={theme.palette.warning.main}
                subtitle={`Média por usuário: ${formatCurrency(metrics.conversions.revenuePer)}`}
              />
            </Grid>
          </Grid>

          <Paper sx={{ width: '100%', mb: 4 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label="Categorias" />
              <Tab label="Conteúdos" />
              <Tab label="Usuários" />
              <Tab label="Conversões" />
            </Tabs>

            {/* Tab Categorias */}
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Categoria</TableCell>
                          <TableCell align="right">Visualizações</TableCell>
                          <TableCell align="right">Visualizações Únicas</TableCell>
                          <TableCell align="right">Taxa de Retenção</TableCell>
                          <TableCell align="right">Tempo Médio</TableCell>
                          <TableCell align="right">Taxa de Conversão</TableCell>
                          <TableCell align="right">Receita</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(metrics.categories.categorySpecific).map(([id, category]) => {
                          const typedCategory = category as CategoryEntry;
                          return (
                            <TableRow key={id}>
                              <TableCell>{typedCategory.name}</TableCell>
                              <TableCell align="right">{typedCategory.views}</TableCell>
                              <TableCell align="right">{typedCategory.uniqueViews}</TableCell>
                              <TableCell align="right">{formatPercentage(typedCategory.retentionRate)}</TableCell>
                              <TableCell align="right">{formatDuration(typedCategory.averageTimeSpent)}</TableCell>
                              <TableCell align="right">{formatPercentage(typedCategory.conversionRate)}</TableCell>
                              <TableCell align="right">{formatCurrency(typedCategory.revenue)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>

            {/* Tab Conteúdos */}
            <TabPanel value={tabValue} index={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Conteúdo</TableCell>
                          <TableCell align="right">Visualizações</TableCell>
                          <TableCell align="right">Visualizações Únicas</TableCell>
                          <TableCell align="right">Tempo Médio</TableCell>
                          <TableCell align="right">Taxa de Conclusão</TableCell>
                          <TableCell align="right">Taxa de Retenção</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(metrics.contents.contentSpecific).map(([id, content]) => {
                          const typedContent = content as ContentEntry;
                          return (
                            <TableRow key={id}>
                              <TableCell>{typedContent.title}</TableCell>
                              <TableCell align="right">{typedContent.views}</TableCell>
                              <TableCell align="right">{typedContent.uniqueViews}</TableCell>
                              <TableCell align="right">{formatDuration(typedContent.averageWatchTime)}</TableCell>
                              <TableCell align="right">{formatPercentage(typedContent.completionRate)}</TableCell>
                              <TableCell align="right">{formatPercentage(typedContent.retentionRate)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>

            {/* Tab Usuários */}
            <TabPanel value={tabValue} index={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Métricas de Engajamento
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Usuários Ativos: {metrics.users.active}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Usuários Premium: {metrics.users.premium}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Tempo Médio de Sessão: {formatDuration(metrics.users.averageSessionTime)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Tempo Médio de Visualização: {formatDuration(metrics.users.averageWatchTime)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Taxa de Retenção: {formatPercentage(metrics.users.retentionRate)}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Usuário</TableCell>
                          <TableCell align="right">Tempo Total</TableCell>
                          <TableCell align="right">Última Atividade</TableCell>
                          <TableCell align="right">Sessões</TableCell>
                          <TableCell align="right">Categorias Vistas</TableCell>
                          <TableCell align="right">Conteúdos Vistos</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(metrics.users.userSpecific).map(([id, user]) => {
                          const typedUser = user as UserEntry;
                          return (
                            <TableRow key={id}>
                              <TableCell>{id}</TableCell>
                              <TableCell align="right">{formatDuration(typedUser.totalTimeSpent)}</TableCell>
                              <TableCell align="right">
                                {formatDistanceToNow(new Date(typedUser.lastActive), { locale: ptBR, addSuffix: true })}
                              </TableCell>
                              <TableCell align="right">{typedUser.sessionCount}</TableCell>
                              <TableCell align="right">{typedUser.categoriesViewed.length}</TableCell>
                              <TableCell align="right">{typedUser.contentsViewed.length}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </TabPanel>

            {/* Tab Conversões */}
            <TabPanel value={tabValue} index={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Métricas de Conversão
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Total de Conversões: {metrics.conversions.total}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Usuários Únicos: {metrics.conversions.uniqueUsers}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Taxa de Conversão: {formatPercentage(metrics.conversions.conversionRate)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Tempo Médio até Conversão: {formatDuration(metrics.conversions.averageTimeToConversion)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Receita Total: {formatCurrency(metrics.conversions.revenueTotal)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Receita Média por Usuário: {formatCurrency(metrics.conversions.revenuePer)}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Top Categorias Convertendo
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Categoria</TableCell>
                            <TableCell align="right">Conversões</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {metrics.conversions.topConvertingCategories.map((category) => (
                            <TableRow key={category.id}>
                              <TableCell>{category.name}</TableCell>
                              <TableCell align="right">{category.conversions}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default Analytics; 