import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { auth, db } from '../firebase';
import { updatePassword } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { Lock as LockIcon, Edit as EditIcon, Phone as PhoneIcon, Email as EmailIcon, Person as PersonIcon } from '@mui/icons-material';
import Navbar from '../components/Navbar';

const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(8),
  },
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  background: 'rgba(32, 32, 32, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
}));

const InfoCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: 'rgba(38, 38, 38, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const SelectedAvatar = styled(Avatar)(({ theme }) => ({
  width: 180,
  height: 180,
  margin: '0 auto',
  marginBottom: theme.spacing(3),
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: '0 4px 20px rgba(229, 9, 20, 0.5)',
}));

const AvatarOption = styled(Avatar)<{ selected: boolean }>(({ theme, selected }) => ({
  width: 80,
  height: 80,
  cursor: 'pointer',
  margin: theme.spacing(1),
  border: selected ? `3px solid ${theme.palette.primary.main}` : '3px solid transparent',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    border: `3px solid ${theme.palette.primary.main}`,
    transform: 'scale(1.05)',
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
}));

export default function User() {
  const [user, setUser] = useState<any>(null);
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');

  const avatarOptions = [
    '/avatars/avatar1.png',
    '/avatars/avatar2.png',
    '/avatars/avatar3.png',
    '/avatars/avatar4.png',
    '/avatars/avatar5.png',
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
          setSelectedAvatar(userDoc.data().avatarUrl || avatarOptions[0]);
        }
      }
    };
    fetchUserData();
  }, []);

  const handleAvatarChange = async (avatarUrl: string) => {
    try {
      if (auth.currentUser) {
        await updateDoc(doc(db, 'users', auth.currentUser.uid), {
          avatarUrl: avatarUrl
        });
        setSelectedAvatar(avatarUrl);
      }
    } catch (error) {
      console.error('Erro ao atualizar avatar:', error);
    }
  };

  const handlePasswordChange = async () => {
    setError('');
    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    try {
      if (auth.currentUser) {
        await updatePassword(auth.currentUser, passwordForm.newPassword);
        setOpenPasswordDialog(false);
        setPasswordForm({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  if (!user) return null;

  return (
    <>
      <Navbar onMenuClick={() => {}} />
      <ProfileContainer maxWidth="lg">
        <Grid container spacing={4}>
          {/* Coluna do Avatar e Informações Principais */}
          <Grid item xs={12} md={4}>
            <ProfileCard elevation={3}>
              <CardContent sx={{ textAlign: 'center', py: 4 }}>
                <SelectedAvatar src={selectedAvatar} />
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {user.name || 'Usuário'}
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  {auth.currentUser?.email}
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<LockIcon />}
                  onClick={() => setOpenPasswordDialog(true)}
                  sx={{ mt: 2 }}
                >
                  Alterar Senha
                </Button>
              </CardContent>
            </ProfileCard>
          </Grid>

          {/* Coluna de Informações Detalhadas */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InfoCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                      Informações Pessoais
                    </Typography>
                    <InfoItem>
                      <PersonIcon />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Nome Completo
                        </Typography>
                        <Typography variant="body1">
                          {user.name || 'Não informado'}
                        </Typography>
                      </Box>
                    </InfoItem>
                    <InfoItem>
                      <EmailIcon />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          E-mail
                        </Typography>
                        <Typography variant="body1">
                          {auth.currentUser?.email}
                        </Typography>
                      </Box>
                    </InfoItem>
                    <InfoItem>
                      <PhoneIcon />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Telefone
                        </Typography>
                        <Typography variant="body1">
                          {user.phone || 'Não informado'}
                        </Typography>
                      </Box>
                    </InfoItem>
                  </CardContent>
                </InfoCard>
              </Grid>

              {/* Seção de Avatares */}
              <Grid item xs={12}>
                <InfoCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                      Escolha seu avatar
                    </Typography>
                    <Grid container justifyContent="center" spacing={2}>
                      {avatarOptions.map((avatar, index) => (
                        <Grid item key={index}>
                          <AvatarOption
                            src={avatar}
                            selected={selectedAvatar === avatar}
                            onClick={() => handleAvatarChange(avatar)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </InfoCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Dialog de Alteração de Senha */}
        <Dialog 
          open={openPasswordDialog} 
          onClose={() => setOpenPasswordDialog(false)}
          PaperProps={{
            sx: {
              background: 'rgba(38, 38, 38, 0.95)',
              backdropFilter: 'blur(10px)',
            }
          }}
        >
          <DialogTitle>Alterar Senha</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Nova Senha"
              type="password"
              fullWidth
              value={passwordForm.newPassword}
              onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Confirmar Nova Senha"
              type="password"
              fullWidth
              value={passwordForm.confirmPassword}
              onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
            />
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPasswordDialog(false)}>Cancelar</Button>
            <Button onClick={handlePasswordChange} variant="contained">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </ProfileContainer>
    </>
  );
} 