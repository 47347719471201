import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  AuthError,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export default function Login() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetError, setResetError] = useState('');
  const navigate = useNavigate();
  const { user, isAdmin, loading: authLoading } = useAuth();

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      if (user && !authLoading) {
        try {
          const route = isAdmin ? '/admin/home' : '/home';
          navigate(route, { replace: true });
        } catch (error) {
          console.error('Erro ao redirecionar:', error);
        }
      }
    };

    checkAuthAndRedirect();
  }, [user, isAdmin, authLoading, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      if (isRegistering) {
        // Verificar se o email já existe
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        if (signInMethods.length > 0) {
          throw new Error('Este email já está em uso. Por favor, faça login ou use outro email.');
        }

        // Criar novo usuário
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
        // Criar documento do usuário no Firestore
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          email,
          name,
          role: 'user',
          categoryAccess: [],
          createdAt: new Date(),
        });

        setSuccess('Usuário criado com sucesso! Você será redirecionado para a página de login.');
        setTimeout(() => {
          setIsRegistering(false);
          setEmail('');
          setPassword('');
          setName('');
        }, 2000);
      } else {
        // Login
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (userCredential.user) {
          setSuccess('Login realizado com sucesso! Redirecionando...');
          // O useEffect cuidará do redirecionamento após 1.5 segundos
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        }
      }
    } catch (error) {
      console.error('Erro durante o login:', error);
      let errorMessage = 'Ocorreu um erro. Tente novamente.';
      
      if (error instanceof Error) {
        const authError = error as AuthError;
        switch (authError.code) {
          case 'auth/invalid-email':
            errorMessage = 'Email inválido.';
            break;
          case 'auth/user-not-found':
            errorMessage = 'Usuário não encontrado.';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Senha incorreta.';
            break;
          case 'auth/network-request-failed':
            errorMessage = 'Erro de conexão. Verifique sua internet.';
            break;
          default:
            errorMessage = 'Erro ao processar sua solicitação. Tente novamente.';
        }
      }
      
      setError(errorMessage);
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    setResetError('');
    setResetSuccess(false);
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess(true);
      setTimeout(() => {
        setResetPasswordDialog(false);
        setResetEmail('');
        setResetSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Erro ao enviar email de recuperação:', error);
      const authError = error as AuthError;
      switch (authError.code) {
        case 'auth/invalid-email':
          setResetError('Email inválido.');
          break;
        case 'auth/user-not-found':
          setResetError('Usuário não encontrado.');
          break;
        default:
          setResetError('Erro ao enviar email de recuperação. Tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url('/bg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              backdropFilter: 'blur(10px)',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Typography component="h1" variant="h4" sx={{ color: 'white', mb: 4, fontWeight: 'bold' }}>
              Entrar
            </Typography>

            {error && (
              <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
                {error}
              </Alert>
            )}

            {success && (
              <Alert severity="success" sx={{ width: '100%', mb: 2 }}>
                {success}
              </Alert>
            )}

            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
              {isRegistering && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'white',
                      '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.23)' },
                      '&:hover fieldset': { borderColor: 'white' },
                    },
                    '& .MuiInputLabel-root': { color: 'rgba(255, 255, 255, 0.7)' },
                  }}
                />
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email ou número de celular"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    sx={{
                      color: '#b3b3b3',
                      '&.Mui-checked': { color: '#b3b3b3' },
                    }}
                  />
                }
                label="Lembre-se de mim"
                sx={{ color: '#b3b3b3', mt: 1 }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  backgroundColor: '#e50914',
                  '&:hover': {
                    backgroundColor: '#b2070e',
                  },
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: 'white' }} />
                ) : (
                  'Entrar'
                )}
              </Button>

              <Button
                fullWidth
                onClick={() => setResetPasswordDialog(true)}
                sx={{
                  color: '#b3b3b3',
                  textTransform: 'none',
                  '&:hover': {
                    color: '#fff',
                  },
                }}
              >
                Esqueceu sua senha?
              </Button>

              <Button
                fullWidth
                variant="text"
                onClick={() => window.location.href = 'https://metyflix.com/v3qf4gv/'}
                sx={{
                  color: '#fff',
                  textTransform: 'none',
                  backgroundColor: '#333',
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: '#444',
                  },
                }}
              >
                CLIQUE E CONHEÇA 🔥METYFLIX🔥
              </Button>

              <Box sx={{ mt: 2, color: '#b3b3b3' }}>
                <Typography variant="body2" sx={{ display: 'inline' }}>
                  Primeira vez aqui? 
                </Typography>
                <Button
                  onClick={() => window.location.href = 'https://metyflix.com/v3qf4gv/'}
                  sx={{
                    color: '#fff',
                    textTransform: 'none',
                    ml: 1,
                    p: 0,
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Assine agora.
                </Button>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ color: '#8c8c8c' }}>
                  Esta página é protegida pelo Google reCAPTCHA para garantir que você não é um robô.{' '}
                  <Button
                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                    sx={{
                      color: '#0071eb',
                      textTransform: 'none',
                      p: 0,
                      minWidth: 'auto',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Saiba mais.
                  </Button>
                </Typography>

                <Collapse in={showMoreInfo}>
                  <Typography variant="body2" sx={{ color: '#8c8c8c', mt: 1 }}>
                    As informações recolhidas pelo Google reCAPTCHA estão sujeitas à{' '}
                    <Button
                      component="a"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: '#0071eb',
                        textTransform: 'none',
                        p: 0,
                        minWidth: 'auto',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Política de Privacidade
                    </Button>
                    {' '}e aos{' '}
                    <Button
                      component="a"
                      href="https://policies.google.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: '#0071eb',
                        textTransform: 'none',
                        p: 0,
                        minWidth: 'auto',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Termos de Uso
                    </Button>
                    {' '}do Google e são usadas para oferecer, manter e melhorar o serviço reCAPTCHA e por questões de segurança (não são usadas para exibir anúncios personalizados pelo Google).
                  </Typography>
                </Collapse>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>

      {/* Diálogo de Recuperação de Senha */}
      <Dialog 
        open={resetPasswordDialog} 
        onClose={() => {
          setResetPasswordDialog(false);
          setResetEmail('');
          setResetError('');
          setResetSuccess(false);
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            color: 'white',
            minWidth: { xs: '90%', sm: '400px' },
          }
        }}
      >
        <DialogTitle sx={{ borderBottom: '1px solid #333' }}>
          Recuperar Senha
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {resetSuccess ? (
            <Alert severity="success" sx={{ mt: 1 }}>
              Email de recuperação enviado com sucesso! Verifique sua caixa de entrada.
            </Alert>
          ) : (
            <>
              <Typography variant="body2" sx={{ mb: 2, color: '#b3b3b3' }}>
                Digite seu email e enviaremos instruções para redefinir sua senha.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                error={!!resetError}
                helperText={resetError}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                  '& .MuiFormHelperText-root': {
                    color: '#e87c03',
                  },
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px solid #333', p: 2 }}>
          <Button
            onClick={() => {
              setResetPasswordDialog(false);
              setResetEmail('');
              setResetError('');
              setResetSuccess(false);
            }}
            sx={{ 
              color: '#b3b3b3',
              '&:hover': { color: '#fff' }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleResetPassword}
            disabled={loading || resetSuccess}
            variant="contained"
            sx={{
              backgroundColor: '#e50914',
              '&:hover': {
                backgroundColor: '#b2070e',
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Enviar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 