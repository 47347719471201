import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Card,
  CardContent,
  Switch,
  Divider,
  Autocomplete,
  Chip,
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Settings as SettingsIcon, Lock as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import type { Category } from '../types';
import { auth } from '../firebase';

interface User {
  id: string;
  email: string;
  name: string;
  role: 'admin' | 'user';
  categoryAccess: string[];
  createdAt: Date;
  premiumCategories?: string[];
  avatarUrl?: string;
}

export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [open, setOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    role: 'user' as 'admin' | 'user',
    categoryAccess: [] as string[],
  });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    categoryId: string;
    action: 'grant' | 'revoke';
  }>({
    open: false,
    categoryId: '',
    action: 'grant',
  });

  useEffect(() => {
    fetchUsers();
    fetchCategories();
  }, []);

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const usersData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as User[];
    setUsers(usersData);
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Category[];
    setCategories(categoriesData.filter(category => category.isPremium));
  };

  const handleOpen = (user?: User) => {
    if (user) {
      setEditingUser(user);
      setFormData({
        email: user.email,
        name: user.name,
        role: user.role,
        categoryAccess: user.categoryAccess,
      });
    } else {
      setEditingUser(null);
      setFormData({
        email: '',
        name: '',
        role: 'user',
        categoryAccess: [],
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingUser(null);
    setFormData({
      email: '',
      name: '',
      role: 'user',
      categoryAccess: [],
    });
  };

  const handleSubmit = async () => {
    try {
      if (editingUser) {
        await updateDoc(doc(db, 'users', editingUser.id), {
          email: formData.email,
          name: formData.name,
          role: formData.role,
          categoryAccess: formData.categoryAccess,
        });
      } else {
        await addDoc(collection(db, 'users'), {
          email: formData.email,
          name: formData.name,
          role: formData.role,
          categoryAccess: formData.categoryAccess,
          createdAt: new Date(),
        });
      }
      handleClose();
      fetchUsers();
    } catch (error) {
      console.error('Erro ao salvar usuário:', error);
    }
  };

  const handleDelete = async (id: string, userRole: string) => {
    // Não permite deletar usuários admin
    if (userRole === 'admin') {
      alert('Não é possível remover usuários administradores.');
      return;
    }

    const confirmDelete = window.confirm('Tem certeza que deseja deletar este usuário?');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'users', id));
      fetchUsers();
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      alert('Erro ao deletar usuário. Por favor, tente novamente.');
    }
  };

  const handleCategoryToggle = (categoryId: string) => {
    setFormData(prev => ({
      ...prev,
      categoryAccess: prev.categoryAccess.includes(categoryId)
        ? prev.categoryAccess.filter(id => id !== categoryId)
        : [...prev.categoryAccess, categoryId],
    }));
  };

  const handleUserChange = (event: any, newValue: User | null) => {
    setSelectedUser(newValue);
    if (newValue) {
      setOpenPremiumDialog(true);
    }
  };

  const handleClosePremiumSettings = () => {
    setOpenPremiumDialog(false);
  };

  const handleAccessChange = async (categoryId: string, action: 'grant' | 'revoke') => {
    if (!selectedUser) return;

    try {
      const userRef = doc(db, 'users', selectedUser.id);
      const updatedPremiumCategories = action === 'grant'
        ? [...(selectedUser.premiumCategories || []), categoryId]
        : (selectedUser.premiumCategories || []).filter((id: string) => id !== categoryId);

      await updateDoc(userRef, {
        premiumCategories: updatedPremiumCategories,
      });

      // Atualiza o estado local
      setSelectedUser({
        ...selectedUser,
        premiumCategories: updatedPremiumCategories,
      });

      // Atualiza a lista de usuários
      setUsers(users.map(user => 
        user.id === selectedUser.id 
          ? { ...user, premiumCategories: updatedPremiumCategories }
          : user
      ));

      setConfirmDialog({ open: false, categoryId: '', action: 'grant' });
    } catch (error) {
      console.error('Erro ao atualizar acesso:', error);
      alert('Erro ao atualizar acesso. Tente novamente.');
    }
  };

  const hasAccessToCategory = (categoryId: string) => {
    return selectedUser?.premiumCategories?.includes(categoryId) || false;
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Gerenciar Usuários
      </Typography>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Autocomplete
            value={selectedUser}
            onChange={handleUserChange}
            options={users}
            getOptionLabel={(user) => `${user.name} (${user.email})`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecionar Usuário"
                variant="outlined"
                fullWidth
              />
            )}
            renderOption={(props, user) => (
              <Box component="li" {...props}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body1">{user.name}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {user.email} • {user.role}
                  </Typography>
                </Box>
              </Box>
            )}
          />
        </CardContent>
      </Card>

      <Dialog
        open={openPremiumDialog}
        onClose={handleClosePremiumSettings}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Gerenciar Acesso Premium - {selectedUser?.name}
        </DialogTitle>
        <DialogContent>
          <List>
            {categories.map((category) => (
              <ListItem key={category.id}>
                <ListItemText
                  primary={category.name}
                  secondary={category.description}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={hasAccessToCategory(category.id)}
                    onChange={() => 
                      handleAccessChange(
                        category.id,
                        hasAccessToCategory(category.id) ? 'revoke' : 'grant'
                      )
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          
          <Divider sx={{ my: 2 }} />
          
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Permissões de Administrador
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={selectedUser?.role === 'admin'}
                  onChange={async () => {
                    if (!selectedUser) return;
                    
                    const newRole = selectedUser.role === 'admin' ? 'user' : 'admin';
                    const confirmMessage = newRole === 'admin' 
                      ? `Tem certeza que deseja tornar ${selectedUser.name} um administrador?` 
                      : `Tem certeza que deseja remover os privilégios de administrador de ${selectedUser.name}?`;
                    
                    if (window.confirm(confirmMessage)) {
                      try {
                        const userRef = doc(db, 'users', selectedUser.id);
                        await updateDoc(userRef, {
                          role: newRole,
                        });
                        
                        // Atualiza o estado local
                        setSelectedUser({
                          ...selectedUser,
                          role: newRole,
                        });
                        
                        // Atualiza a lista de usuários
                        setUsers(users.map(user => 
                          user.id === selectedUser.id 
                            ? { ...user, role: newRole }
                            : user
                        ));
                      } catch (error) {
                        console.error('Erro ao atualizar permissões:', error);
                        alert('Erro ao atualizar permissões. Tente novamente.');
                      }
                    }
                  }}
                />
              }
              label={selectedUser?.role === 'admin' ? 'Remover privilégios de administrador' : 'Tornar administrador'}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePremiumSettings} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      >
        <DialogTitle>
          Confirmar {confirmDialog.action === 'grant' ? 'Liberação' : 'Revogação'} de Acesso
        </DialogTitle>
        <DialogContent>
          <Typography>
            Você tem certeza que deseja {confirmDialog.action === 'grant' ? 'liberar' : 'revogar'} o 
            acesso desta categoria para {selectedUser?.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })}>
            Cancelar
          </Button>
          <Button
            color={confirmDialog.action === 'grant' ? 'primary' : 'error'}
            onClick={() => handleAccessChange(confirmDialog.categoryId, confirmDialog.action)}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 