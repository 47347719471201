import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Switch,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
  DragIndicator as DragIndicatorIcon
} from '@mui/icons-material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { toast } from 'react-hot-toast';
import { notificationsService } from '../../services/notifications';
import type { Notification } from '../../types';
import CreateNotificationDialog from './CreateNotificationDialog';

interface SortableItemProps {
  notification: Notification;
  onEdit: (notification: Notification) => void;
  onDelete: (id: string) => void;
  onToggle: (notification: Notification) => void;
}

function SortableItem({ notification, onEdit, onDelete, onToggle }: SortableItemProps): JSX.Element {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: notification.id || '' });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      sx={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        '&:last-child': {
          borderBottom: 'none',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        pr: '160px',
        position: 'relative',
      }}
    >
      <Box {...attributes} {...listeners} sx={{ mr: 2, cursor: 'grab', color: 'white' }}>
        <DragIndicatorIcon />
      </Box>
      
      {notification.thumbnailUrl && (
        <Box sx={{ width: 100, height: 60, mr: 2, position: 'relative', flexShrink: 0 }}>
          <img
            src={notification.thumbnailUrl}
            alt={notification.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
        </Box>
      )}
      
      <ListItemText
        primary={
          <Typography variant="subtitle1" sx={{ color: 'white' }}>
            {notification.title}
          </Typography>
        }
        sx={{
          mr: 2,
          flex: '1 1 auto',
          minWidth: 0,
        }}
      />
      
      <Box
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Switch
          checked={notification.isActive}
          onChange={() => onToggle(notification)}
          color="primary"
        />
        <IconButton onClick={() => onEdit(notification)} sx={{ color: 'white' }}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(notification.id!)} sx={{ color: 'white' }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
}

export default function Notifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingNotification, setEditingNotification] = useState<Notification | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const notifications = await notificationsService.list();
      setNotifications(notifications);
    } catch (error) {
      console.error('Erro ao carregar notificações:', error);
      toast.error('Erro ao carregar notificações');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = notifications.findIndex((n) => n.id === active.id);
    const newIndex = notifications.findIndex((n) => n.id === over.id);

    const updatedItems = arrayMove(notifications, oldIndex, newIndex);
    setNotifications(updatedItems);

    try {
      await notificationsService.updateOrder(updatedItems);
    } catch (error) {
      console.error('Erro ao atualizar ordem:', error);
      toast.error('Erro ao atualizar ordem');
      fetchNotifications();
    }
  };

  const handleToggleActive = async (notification: Notification) => {
    try {
      await notificationsService.toggle(notification.id!);
      await fetchNotifications();
      toast.success('Status da notificação atualizado');
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      toast.error('Erro ao atualizar status');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Tem certeza que deseja excluir esta notificação?')) {
      return;
    }

    try {
      await notificationsService.delete(id);
      await fetchNotifications();
      toast.success('Notificação excluída com sucesso');
    } catch (error) {
      console.error('Erro ao excluir notificação:', error);
      toast.error('Erro ao excluir notificação');
    }
  };

  const handleEdit = (notification: Notification) => {
    setEditingNotification(notification);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingNotification(null);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" component="h1">
          Notificações
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Nova Notificação
        </Button>
      </Box>

      <Paper sx={{ bgcolor: '#141414', color: 'white' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={notifications.map(n => n.id!)}
            strategy={verticalListSortingStrategy}
          >
            <List>
              {notifications.map((notification) => (
                <SortableItem
                  key={notification.id}
                  notification={notification}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  onToggle={handleToggleActive}
                />
              ))}
            </List>
          </SortableContext>
        </DndContext>
      </Paper>

      <CreateNotificationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSuccess={fetchNotifications}
        editingNotification={editingNotification}
      />
    </Container>
  );
} 