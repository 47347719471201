import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { User, signOut as firebaseSignOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

// Definição dos tipos
export type UserData = {
  name: string;
  email: string;
  role: 'admin' | 'user';
  categoryAccess?: string[];
  avatarUrl?: string;
  premiumCategories?: string[];
};

interface AuthContextType {
  user: User | null;
  userData: UserData | null;
  loading: boolean;
  isAdmin: boolean;
  signOut: () => Promise<void>;
}

// Criação do contexto com um valor padrão seguro
const AuthContext = createContext<AuthContextType>({
  user: null,
  userData: null,
  loading: true,
  isAdmin: false,
  signOut: async () => {
    throw new Error('AuthContext não foi inicializado');
  },
});

// Provider Component
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // Função de logout
  const handleSignOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      setUserData(null);
      setIsAdmin(false);
      // Limpar qualquer dado de sessão adicional se necessário
      sessionStorage.clear();
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
      throw error;
    }
  };

  // Função para buscar dados do usuário
  const fetchUserData = async (user: User) => {
    // Busca na coleção users
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data() as UserData;
      setUserData(userData);
      // Verifica se o usuário é admin baseado na role
      setIsAdmin(userData.role === 'admin');
      setUser(user);
      return true;
    }

    return false;
  };

  // Efeito para monitorar mudanças na autenticação
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(true);
      try {
        if (user) {
          const userFound = await fetchUserData(user);
          if (!userFound) {
            // Se não encontrar o documento do usuário, fazer logout
            console.error('Documento do usuário não encontrado');
            await handleSignOut();
          }
        } else {
          setUser(null);
          setUserData(null);
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Erro ao carregar dados do usuário:', error);
        // Em caso de erro, fazer logout por segurança
        await handleSignOut();
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider 
      value={{
        user,
        userData,
        loading,
        isAdmin,
        signOut: handleSignOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Hook personalizado para usar o contexto
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
}; 