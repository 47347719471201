import React, { useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { PlayArrow, Info } from '@mui/icons-material';
import { analyticsService } from '../services/analytics';

interface HeroBannerProps {
  title: string;
  description: string;
  imageUrl: string;
  onPlay?: () => void;
  onMoreInfo?: () => void;
}

export const HeroBanner: React.FC<HeroBannerProps> = ({
  title,
  description,
  imageUrl,
  onPlay,
  onMoreInfo,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageError(true);
    setImageLoaded(true);
  };

  const handlePlayClick = async () => {
    try {
      await analyticsService.trackEvent({
        type: 'button_click',
        id: 'hero_play',
        timestamp: new Date(),
        metadata: {
          buttonType: 'play',
          title
        }
      });
      onPlay?.();
    } catch (error) {
      console.error('Erro ao rastrear clique no botão play:', error);
      onPlay?.();
    }
  };

  const handleMoreInfoClick = async () => {
    try {
      await analyticsService.trackEvent({
        type: 'button_click',
        id: 'hero_info',
        timestamp: new Date(),
        metadata: {
          buttonType: 'info',
          title
        }
      });
      onMoreInfo?.();
    } catch (error) {
      console.error('Erro ao rastrear clique no botão info:', error);
      onMoreInfo?.();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '85vh',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!imageLoaded && (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      {!imageError && (
        <Box
          component="img"
          src={imageUrl}
          alt={title}
          onLoad={handleImageLoad}
          onError={handleImageError}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: imageLoaded ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
        />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `
            linear-gradient(0deg, rgba(20,20,20,1) 0%, rgba(20,20,20,0.7) 30%, rgba(20,20,20,0.4) 50%, rgba(20,20,20,0.3) 65%, rgba(20,20,20,0.1) 100%),
            linear-gradient(90deg, rgba(20,20,20,0.8) 0%, rgba(20,20,20,0) 50%)
          `,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: { xs: '2rem', md: '4rem' },
          paddingTop: { xs: '15vh', md: '10vh' },
          color: 'white',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '250px',
            background: 'linear-gradient(180deg, transparent 0%, rgba(20,20,20,1) 100%)',
            pointerEvents: 'none'
          }
        }}
      >
        <Box sx={{ maxWidth: '800px' }}>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              lineHeight: 1.2
            }}
          >
            {title}
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 4, 
              fontSize: { xs: '1rem', md: '1.2rem' },
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
              maxWidth: '600px'
            }}
          >
            {description}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              size="large"
              startIcon={<PlayArrow />}
              onClick={handlePlayClick}
              sx={{
                backgroundColor: 'white',
                color: 'black',
                px: 4,
                py: 1.5,
                fontSize: '1.1rem',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.8)',
                },
              }}
            >
              Assistir
            </Button>
            <Button
              variant="outlined"
              size="large"
              startIcon={<Info />}
              onClick={handleMoreInfoClick}
              sx={{
                borderColor: 'white',
                color: 'white',
                px: 4,
                py: 1.5,
                fontSize: '1.1rem',
                '&:hover': {
                  borderColor: 'rgba(255,255,255,0.8)',
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
            >
              Mais Informações
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}; 