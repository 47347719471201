import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Typography, Skeleton, Menu, MenuItem, Button } from '@mui/material';
import { collection, getDocs, query, orderBy, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import Navbar from '../components/Navbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from '../hooks/useAuth';

interface Category {
  id: string;
  name: string;
  description: string;
  imageUrl?: string;
  isPremium?: boolean;
  redirectUrl?: string;
  order: number;
}

export default function UserCategories() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [userPremiumCategories, setUserPremiumCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRef = collection(db, 'categories');
        const categoriesSnapshot = await getDocs(query(categoriesRef, orderBy('order', 'asc')));
        const categoriesData = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Category[];
        setCategories(categoriesData);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
        setLoading(false);
      }
    };

    const fetchUserPremiumCategories = async () => {
      try {
        if (!user) return;

        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserPremiumCategories(userData.premiumCategories || []);
        }
      } catch (error) {
        console.error('Erro ao buscar categorias premium do usuário:', error);
      }
    };

    fetchCategories();
    fetchUserPremiumCategories();
  }, [user]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const canAccessCategory = (categoryId: string) => {
    return userPremiumCategories.includes(categoryId);
  };

  const handleCategoryClick = (category: Category) => {
    if (category.isPremium && !canAccessCategory(category.id)) {
      if (category.redirectUrl) {
        window.location.href = category.redirectUrl;
      }
      return;
    }
    navigate(`/categories/${category.id}`);
  };

  // Organiza as categorias em linhas de 4
  const categoriesInRows = categories.reduce<Category[][]>((acc, category, index) => {
    const rowIndex = Math.floor(index / 4);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(category);
    return acc;
  }, []);

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: '#141414',
      color: 'white',
      position: 'relative',
    }}>
      <Navbar onMenuClick={() => {}} />
      <Box sx={{ pt: 8 }}>
        <Container maxWidth="xl" sx={{ py: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4" component="h1" sx={{ mr: 2 }}>
              Categorias
            </Typography>
            <Button
              onClick={handleMenuClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
            >
              Ver todas as categorias
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  bgcolor: 'rgba(0, 0, 0, 0.9)',
                  color: 'white',
                  maxHeight: '80vh',
                  maxWidth: '80vw',
                  mt: 1,
                },
              }}
            >
              <Box sx={{ p: 2 }}>
                {categoriesInRows.map((row, rowIndex) => (
                  <Box
                    key={rowIndex}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(4, 1fr)',
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    {row.map((category) => (
                      <MenuItem
                        key={category.id}
                        onClick={() => handleCategoryClick(category)}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          minWidth: '200px',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          },
                        }}
                      >
                        {category.name}
                        {category.isPremium && (
                          <LockIcon sx={{ ml: 1, fontSize: 16, color: '#9b753f' }} />
                        )}
                      </MenuItem>
                    ))}
                  </Box>
                ))}
              </Box>
            </Menu>
          </Box>

          <Grid container spacing={3}>
            {loading
              ? Array.from(new Array(8)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: '100%',
                        height: 0,
                        paddingTop: '125%',
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: 2,
                      }}
                    />
                  </Grid>
                ))
              : categories.map((category) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={category.id} id={category.id}>
                    <Box
                      onClick={() => handleCategoryClick(category)}
                      sx={{
                        position: 'relative',
                        width: '100%',
                        height: 0,
                        paddingTop: '125%', // Proporção 1080x1350
                        borderRadius: 2,
                        overflow: 'hidden',
                        cursor: 'pointer',
                        '&:hover': {
                          '& .category-overlay': {
                            opacity: 1,
                          },
                          '& img': {
                            transform: 'scale(1.05)',
                          },
                        },
                      }}
                    >
                      <Box
                        component="img"
                        src={category.imageUrl || '/placeholder-image.jpg'}
                        alt={category.name}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                      
                      {/* Overlay com gradiente e informações */}
                      <Box
                        className="category-overlay"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.2) 100%)',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          padding: 2,
                          opacity: 0.7,
                          transition: 'opacity 0.3s ease',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                            {category.name}
                          </Typography>
                          {category.isPremium && (
                            <LockIcon sx={{ color: '#9b753f' }} />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
} 