import React from 'react';
import { Box, Typography } from '@mui/material';

export default function AdminHome() {
  return (
    <Box 
      sx={{ 
        minHeight: 'calc(100vh - 64px)', // Altura total menos a altura do header
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
        textAlign: 'center'
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
        Bem-vindo ao topo, visionário!
      </Typography>
      <Typography variant="h5" sx={{ fontStyle: 'italic', maxWidth: '800px', mt: 2, color: 'white' }}>
        "Cada pensamento de abundância que você cultiva é um passo em direção à prosperidade que te espera."
      </Typography>
    </Box>
  );
} 