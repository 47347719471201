import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  PlayCircle as PlayCircleIcon,
  Lock as LockIcon,
  DragIndicator as DragIndicatorIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, writeBatch, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { Content, Category } from '../types';

interface SortableItemProps {
  content: Content;
  category?: Category;
  onEdit: (content: Content) => void;
  onDelete: (id: string) => void;
  onPreview: (videoUrl: string) => void;
}

function SortableItem({ content, category, onEdit, onDelete, onPreview }: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: content.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isPremium = category?.isPremium;

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      sx={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        '&:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Box {...attributes} {...listeners} sx={{ mr: 2, cursor: 'grab' }}>
        <DragIndicatorIcon />
      </Box>
      
      <Box sx={{ width: 120, height: 68, mr: 2, position: 'relative' }}>
        {content.thumbnailUrl ? (
          <img
            src={content.thumbnailUrl}
            alt={content.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
              filter: isPremium ? 'brightness(0.8)' : 'none',
            }}
          />
        ) : content.contentType === 'video' ? (
          <video
            src={content.fileUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
              filter: isPremium ? 'brightness(0.8)' : 'none',
            }}
            preload="metadata"
          />
        ) : (
          <img
            src={'/placeholder-video.jpg'}
            alt={content.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
              filter: isPremium ? 'brightness(0.8)' : 'none',
            }}
          />
        )}
        {isPremium && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              p: 0.5,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '0 4px 0 4px',
            }}
          >
            <LockIcon sx={{ fontSize: 16, color: 'gold' }} />
          </Box>
        )}
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
          onClick={() => {
            const url = content.contentUrl || content.videoUrl || content.fileUrl || '';
            onPreview(url);
          }}
        >
          <PlayCircleIcon />
        </IconButton>
      </Box>
      
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {content.title}
            {isPremium && (
              <LockIcon sx={{ ml: 1, fontSize: 16, color: 'gold' }} />
            )}
          </Box>
        }
        secondary={content.description}
      />
      
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={() => onEdit(content)} sx={{ mr: 1 }}>
          <EditIcon />
        </IconButton>
        <IconButton edge="end" onClick={() => onDelete(content.id)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

interface FormData {
  title: string;
  description: string;
  categoryId: string;
  contentType: 'video' | 'image' | 'pdf';
  fileUrl: string;
  thumbnailUrl: string;
}

export default function Contents() {
  const [contents, setContents] = useState<Content[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [videoPreviewOpen, setVideoPreviewOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [editingContent, setEditingContent] = useState<Content | null>(null);
  const [uploading, setUploading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    categoryId: '',
    contentType: 'video',
    fileUrl: '',
    thumbnailUrl: '',
  });
  const [showThumbnailSelector, setShowThumbnailSelector] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchContents(selectedCategoryId);
    } else {
      setContents([]);
    }
  }, [selectedCategoryId]);

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Category[];
    const sortedCategories = categoriesData.sort((a, b) => a.order - b.order);
    setCategories(sortedCategories);
  };

  const fetchContents = async (categoryId: string) => {
    const q = query(collection(db, 'contents'), where('categoryId', '==', categoryId));
    const querySnapshot = await getDocs(q);
    const contentsData = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const contentData = {
        id: doc.id,
        title: data.title || '',
        description: data.description || '',
        categoryId: data.categoryId || '',
        contentType: data.contentType || 'video',
        videoUrl: data.videoUrl || '',
        fileUrl: data.fileUrl || '',
        contentUrl: data.contentUrl || '',
        thumbnailUrl: data.thumbnailUrl || '',
        order: data.order || 0,
        createdAt: data.createdAt || new Date(),
        hasSeasons: data.hasSeasons || false
      } as Content;
      return contentData;
    });
    
    const sortedContents = contentsData.sort((a, b) => a.order - b.order);
    setContents(sortedContents);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = contents.findIndex((content) => content.id === active.id);
    const newIndex = contents.findIndex((content) => content.id === over.id);

    const updatedItems = arrayMove(contents, oldIndex, newIndex).map(
      (item: Content, index: number) => ({
        ...item,
        order: index,
      })
    );

    setContents(updatedItems);

    // Atualizar no Firestore
    const batch = writeBatch(db);
    updatedItems.forEach((content: Content) => {
      const contentRef = doc(db, 'contents', content.id);
      batch.update(contentRef, { order: content.order });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Erro ao atualizar ordem dos conteúdos:', error);
      fetchContents(selectedCategoryId);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, type: 'content' | 'thumbnail') => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploading(true);
      const folder = type === 'content' ? 'contents' : 'thumbnails';
      const maxSize = type === 'content' ? 500 * 1024 * 1024 : 2 * 1024 * 1024; // 500MB para conteúdo, 2MB para thumbnail

      if (file.size > maxSize) {
        alert(`Arquivo muito grande. Tamanho máximo: ${maxSize / (1024 * 1024)}MB`);
        return;
      }

      const fileRef = ref(storage, `${folder}/${Date.now()}-${file.name}`);
      await uploadBytes(fileRef, file);
      const downloadUrl = await getDownloadURL(fileRef);

      if (type === 'content') {
        // Determinar o tipo de conteúdo baseado na extensão do arquivo
        const extension = file.name.split('.').pop()?.toLowerCase();
        let contentType: 'video' | 'image' | 'pdf';
        
        if (['mp4'].includes(extension || '')) {
          contentType = 'video';
        } else if (['png'].includes(extension || '')) {
          contentType = 'image';
        } else if (['pdf'].includes(extension || '')) {
          contentType = 'pdf';
        } else {
          throw new Error('Tipo de arquivo não suportado');
        }

        setFormData(prev => ({
          ...prev,
          contentType,
          fileUrl: downloadUrl,
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          thumbnailUrl: downloadUrl,
        }));
      }
    } catch (error) {
      console.error(`Erro ao fazer upload do arquivo:`, error);
      alert(`Erro ao fazer upload do arquivo. Tente novamente.`);
    } finally {
      setUploading(false);
    }
  };

  const handleThumbnailCapture = async (thumbnailBlob: Blob) => {
    try {
      setUploading(true);
      const fileRef = ref(storage, `thumbnails/${Date.now()}-thumbnail.jpg`);
      await uploadBytes(fileRef, thumbnailBlob);
      const downloadUrl = await getDownloadURL(fileRef);
      
      setFormData(prev => ({
        ...prev,
        thumbnailUrl: downloadUrl,
      }));
    } catch (error) {
      console.error('Erro ao salvar thumbnail:', error);
      alert('Erro ao salvar thumbnail. Tente novamente.');
    } finally {
      setUploading(false);
    }
  };

  const handleOpen = (content?: Content) => {
    if (content) {
      setEditingContent(content);
      setFormData({
        title: content.title,
        description: content.description,
        categoryId: content.categoryId,
        contentType: content.contentType,
        fileUrl: content.fileUrl || '',
        thumbnailUrl: content.thumbnailUrl || '',
      });
    } else {
      setEditingContent(null);
      setFormData({
        title: '',
        description: '',
        categoryId: selectedCategoryId,
        contentType: 'video',
        fileUrl: '',
        thumbnailUrl: '',
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingContent(null);
    setFormData({
      title: '',
      description: '',
      categoryId: selectedCategoryId,
      contentType: 'video',
      fileUrl: '',
      thumbnailUrl: '',
    });
  };

  const handleSubmit = async () => {
    try {
      const contentData = {
        title: formData.title,
        description: formData.description,
        categoryId: formData.categoryId,
        contentType: formData.contentType,
        fileUrl: formData.fileUrl,
        videoUrl: formData.fileUrl,
        contentUrl: formData.fileUrl,
        thumbnailUrl: formData.thumbnailUrl,
        order: editingContent ? editingContent.order : contents.length,
        createdAt: editingContent ? editingContent.createdAt : new Date(),
        hasSeasons: false
      };

      if (editingContent) {
        await updateDoc(doc(db, 'contents', editingContent.id), contentData);
      } else {
        await addDoc(collection(db, 'contents'), contentData);
      }

      handleClose();
      fetchContents(selectedCategoryId);
    } catch (error) {
      console.error('Erro ao salvar conteúdo:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'contents', id));
      fetchContents(selectedCategoryId);
    } catch (error) {
      console.error('Erro ao deletar conteúdo:', error);
    }
  };

  const handleVideoPreview = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
    setVideoPreviewOpen(true);
  };

  const useDefaultThumbnail = () => {
    if (formData.fileUrl && formData.contentType === 'video') {
      // Limpar a thumbnailUrl para forçar o uso do vídeo como thumbnail
      setFormData(prev => ({
        ...prev,
        thumbnailUrl: '', // Definir como string vazia para usar o vídeo como thumbnail
      }));
      
      setSnackbar({
        open: true,
        message: 'Thumbnail padrão definida com sucesso!',
        severity: 'success'
      });
      
      setUploading(false);
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Conteúdos</Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Categoria</InputLabel>
            <Select
              value={selectedCategoryId}
              onChange={(e) => setSelectedCategoryId(e.target.value)}
              label="Categoria"
            >
              <MenuItem value="">
                <em>Selecione uma categoria</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                  {category.isPremium && (
                    <LockIcon sx={{ ml: 1, fontSize: 16, color: 'gold' }} />
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
            disabled={!selectedCategoryId}
          >
            Novo Conteúdo
          </Button>
        </Box>
      </Box>

      {selectedCategoryId ? (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={contents.map(content => content.id)}
              strategy={verticalListSortingStrategy}
            >
              <List>
                {contents.map((content) => (
                  <SortableItem
                    key={content.id}
                    content={content}
                    category={categories.find(c => c.id === content.categoryId)}
                    onEdit={handleOpen}
                    onDelete={handleDelete}
                    onPreview={handleVideoPreview}
                  />
                ))}
              </List>
            </SortableContext>
          </DndContext>
        </Paper>
      ) : (
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Selecione uma categoria para ver os conteúdos
        </Typography>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingContent ? 'Editar Conteúdo' : 'Novo Conteúdo'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Título"
            fullWidth
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Descrição"
            fullWidth
            multiline
            rows={4}
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              Thumbnail
            </Typography>
            {formData.thumbnailUrl && (
              <Box sx={{ mt: 1, mb: 2 }}>
                <img
                  src={formData.thumbnailUrl}
                  alt="Preview"
                  style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
                />
              </Box>
            )}
            {formData.contentType === 'video' && formData.fileUrl && (
              <Button
                variant="outlined"
                onClick={useDefaultThumbnail}
                sx={{ mb: 2, width: '100%' }}
              >
                Usar Thumbnail Padrão do Vídeo
              </Button>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              disabled={uploading}
              fullWidth
            >
              {uploading ? 'Enviando...' : 'Selecionar Thumbnail'}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'thumbnail')}
              />
            </Button>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              Conteúdo
            </Typography>
            {formData.fileUrl && (
              <Box sx={{ mt: 1, mb: 2 }}>
                {formData.contentType === 'video' && (
                  <video
                    src={formData.fileUrl}
                    controls
                    style={{ maxWidth: '100%', maxHeight: '300px' }}
                  />
                )}
                {formData.contentType === 'image' && (
                  <img
                    src={formData.fileUrl}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                  />
                )}
                {formData.contentType === 'pdf' && (
                  <Box sx={{ 
                    p: 2, 
                    bgcolor: 'rgba(0, 0, 0, 0.1)', 
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1 
                  }}>
                    <Typography>PDF carregado com sucesso</Typography>
                    <Button
                      size="small"
                      onClick={() => window.open(formData.fileUrl, '_blank')}
                    >
                      Visualizar
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
              disabled={uploading}
              fullWidth
            >
              {uploading ? 'Enviando...' : 'Selecionar Conteúdo'}
              <input
                type="file"
                hidden
                accept=".mp4,.png,.pdf"
                onChange={(e) => handleFileUpload(e, 'content')}
              />
            </Button>
            {uploading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress size={24} />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button 
            onClick={handleSubmit} 
            color="primary"
            disabled={uploading || !formData.title || !formData.fileUrl}
          >
            {editingContent ? 'Salvar' : 'Criar'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={videoPreviewOpen}
        onClose={() => setVideoPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <video
            src={selectedVideo}
            controls
            autoPlay
            style={{ width: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVideoPreviewOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
} 