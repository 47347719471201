import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
  alpha,
  styled,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@mui/material';
import {
  Search as SearchIcon,
  AccountCircle,
  ExitToApp as LogoutIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import NotificationBell from "./NotificationBell";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '0ch',
    '&:focus': {
      width: '20ch',
    },
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  marginRight: theme.spacing(3),
  transition: 'color 0.2s ease',
  '&:hover': {
    color: '#E50914',
  },
}));

const LogoContainer = styled('div')({
  width: 150,
  height: 50,
  marginRight: 30,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});

interface SearchResult {
  id: string;
  title: string;
  type: 'content' | 'category';
  thumbnailUrl?: string;
  redirectUrl?: string;
}

interface NavbarProps {
  onMenuClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onMenuClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const { signOut, userData } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut();
      handleClose();
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const handleMyAccount = () => {
    navigate('/user');
    handleClose();
  };

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length < 2) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      // Buscar categorias
      const categoryQuery = query(
        collection(db, 'categories'),
        where('name', '>=', term),
        where('name', '<=', term + '\uf8ff')
      );
      const categorySnapshot = await getDocs(categoryQuery);
      const categoryResults = categorySnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().name,
        type: 'category' as const,
        thumbnailUrl: doc.data().imageUrl,
        redirectUrl: `/categories#${doc.id}`,
      }));

      // Buscar conteúdos
      const contentQuery = query(
        collection(db, 'contents'),
        where('title', '>=', term),
        where('title', '<=', term + '\uf8ff')
      );
      const contentSnapshot = await getDocs(contentQuery);
      const contentResults = contentSnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        type: 'content' as const,
        thumbnailUrl: doc.data().thumbnailUrl,
        redirectUrl: `/content/${doc.id}`,
      }));

      setSearchResults([...categoryResults, ...contentResults]);
    } catch (error) {
      console.error('Erro na pesquisa:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchClick = (result: SearchResult) => {
    setSearchTerm('');
    setSearchResults([]);
    if (result.redirectUrl) {
      navigate(result.redirectUrl);
    }
  };

  const handleSearchClose = () => {
    setSearchAnchorEl(null);
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: isScrolled
          ? 'rgba(0, 0, 0, 0.9)'
          : 'linear-gradient(180deg, rgba(20, 20, 20, 0.9) 0%, rgba(20, 20, 20, 0.7) 35%, rgba(20, 20, 20, 0.4) 70%, transparent 100%)',
        transition: 'background 0.3s ease',
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onMenuClick}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            component="img"
            src="/logo.png"
            alt="Logo"
            sx={{ 
              height: '40px',
              cursor: 'pointer',
              transition: 'opacity 0.3s',
              marginRight: '30px',
              '&:hover': {
                opacity: 0.8
              }
            }}
            onClick={() => navigate('/home')}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NavLink to="/home">Início</NavLink>
            <NavLink to="/categories">Categorias</NavLink>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Pesquisar..."
              inputProps={{ 'aria-label': 'search' }}
              value={searchTerm}
              onChange={handleSearch}
            />
          </Search>

          <NotificationBell />

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            {userData?.avatarUrl ? (
              <Avatar 
                src={userData.avatarUrl} 
                alt={userData.name || ''} 
                sx={{ width: 40, height: 40 }}
              />
            ) : (
              <AccountCircle sx={{ width: 40, height: 40 }} />
            )}
          </IconButton>

          <Popover
            open={Boolean(searchAnchorEl) && searchResults.length > 0}
            anchorEl={searchAnchorEl}
            onClose={handleSearchClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                width: 300,
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                color: 'white',
                mt: 1,
              },
            }}
          >
            <List>
              {searchResults.map((result) => (
                <ListItem
                  key={`${result.type}-${result.id}`}
                  onClick={() => handleSearchClick(result)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  {result.thumbnailUrl && (
                    <Box
                      component="img"
                      src={result.thumbnailUrl}
                      alt={result.title}
                      sx={{
                        width: 40,
                        height: 40,
                        objectFit: 'cover',
                        borderRadius: 1,
                        mr: 2,
                      }}
                    />
                  )}
                  <ListItemText
                    primary={result.title}
                    secondary={result.type === 'category' ? 'Categoria' : 'Conteúdo'}
                    secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
                  />
                </ListItem>
              ))}
            </List>
          </Popover>

          <Menu
            anchorEl={notificationsAnchorEl}
            open={Boolean(notificationsAnchorEl)}
            onClose={handleNotificationsClose}
            PaperProps={{
              sx: {
                bgcolor: 'rgba(0, 0, 0, 0.9)',
                color: 'white',
                minWidth: 300,
                mt: 1,
              },
            }}
          >
            <MenuItem sx={{ color: 'white' }}>
              <Typography>Nenhuma notificação no momento</Typography>
            </MenuItem>
          </Menu>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                color: 'white',
                mt: 1,
              },
            }}
          >
            <MenuItem onClick={handleMyAccount}>
              <PersonIcon sx={{ mr: 1 }} />
              Minha Conta
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <LogoutIcon sx={{ mr: 1 }} />
              Sair
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar; 