import { Analytics } from 'firebase/analytics';
import { analytics } from '../firebase';
import { 
  getFirestore, 
  collection, 
  doc as firestoreDoc, 
  setDoc, 
  getDoc,
  DocumentData,
  DocumentSnapshot,
  DocumentReference,
  increment, 
  query, 
  where, 
  getDocs,
  Timestamp,
  orderBy,
  limit 
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import {
  UserMetrics,
  ContentMetrics,
  CategoryMetrics,
  ConversionMetrics,
  AnalyticsEvent,
  DetailedMetrics
} from './types';

const db = getFirestore();

class AnalyticsService {
  private analytics: Analytics | null;

  constructor() {
    this.analytics = analytics;
  }

  async trackEvent(event: AnalyticsEvent): Promise<void> {
    try {
      if (this.analytics) {
        logEvent(this.analytics, event.type, {
          item_id: event.id,
          ...event.metadata
        });
      }

      const eventRef = firestoreDoc(collection(db, 'analytics_events'), event.id);
      await setDoc(eventRef, {
        ...event,
        timestamp: event.timestamp.toISOString()
      });

      await this.updateMetrics(event);
    } catch (error) {
      console.error('Erro ao registrar evento:', error);
      throw error;
    }
  }

  private async updateMetrics(event: AnalyticsEvent): Promise<void> {
    const metricsRef = firestoreDoc(collection(db, 'analytics_metrics'), 'current');
    const metricsDoc = await getDoc(metricsRef);
    const metrics = metricsDoc.exists() ? metricsDoc.data() as DetailedMetrics : this.getInitialMetrics();

    switch (event.type) {
      case 'session_start':
        if (event.userId) {
          await this.updateUserSession(event.userId, 'start');
        }
        break;

      case 'session_end':
        if (event.userId) {
          await this.updateUserSession(event.userId, 'end');
        }
        break;

      case 'content_view':
        await this.updateContentMetrics(event);
        break;

      case 'content_complete':
        await this.updateContentCompletion(event);
        break;

      case 'content_progress':
        await this.updateContentProgress(event);
        break;

      case 'conversion':
        await this.updateConversionMetrics(event);
        break;

      // ... outros casos existentes ...
    }
  }

  private async updateUserSession(userId: string, type: 'start' | 'end'): Promise<void> {
    const userRef = firestoreDoc(collection(db, 'user_metrics'), userId);
    const now = new Date();

    if (type === 'start') {
      await setDoc(userRef, {
        lastLogin: now,
        sessionCount: increment(1),
        lastActive: now
      }, { merge: true });
    } else {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        const sessionTime = now.getTime() - data.lastActive.toDate().getTime();
        await setDoc(userRef, {
          totalTimeSpent: increment(sessionTime / 60000), // Convertendo para minutos
          lastActive: now
        }, { merge: true });
      }
    }
  }

  private async updateContentMetrics(event: AnalyticsEvent): Promise<void> {
    if (!event.metadata?.contentId || !event.userId) return;

    const contentRef = firestoreDoc(collection(db, 'content_metrics'), event.metadata.contentId);
    const userContentRef = firestoreDoc(collection(db, 'user_content_metrics'), `${event.userId}_${event.metadata.contentId}`);

    // Atualiza métricas gerais do conteúdo
    await setDoc(contentRef, {
      views: increment(1),
      lastViewed: event.timestamp
    }, { merge: true });

    // Registra visualização única por usuário
    await setDoc(userContentRef, {
      userId: event.userId,
      contentId: event.metadata.contentId,
      firstView: event.timestamp,
      lastView: event.timestamp,
      viewCount: increment(1)
    }, { merge: true });
  }

  private async updateContentProgress(event: AnalyticsEvent): Promise<void> {
    if (!event.metadata?.contentId || !event.userId || !event.metadata?.progress) return;

    const userContentRef = firestoreDoc(collection(db, 'user_content_metrics'), `${event.userId}_${event.metadata.contentId}`);
    
    await setDoc(userContentRef, {
      lastProgress: event.metadata.progress,
      watchTime: increment(event.metadata.watchTime || 0),
      lastUpdate: event.timestamp
    }, { merge: true });
  }

  private async updateContentCompletion(event: AnalyticsEvent): Promise<void> {
    if (!event.metadata?.contentId || !event.userId) return;

    const contentRef = firestoreDoc(collection(db, 'content_metrics'), event.metadata.contentId);
    const userContentRef = firestoreDoc(collection(db, 'user_content_metrics'), `${event.userId}_${event.metadata.contentId}`);

    await setDoc(contentRef, {
      completions: increment(1)
    }, { merge: true });

    await setDoc(userContentRef, {
      completed: true,
      completedAt: event.timestamp
    }, { merge: true });
  }

  private async updateConversionMetrics(event: AnalyticsEvent): Promise<void> {
    if (!event.metadata?.categoryId || !event.userId) return;

    const conversionRef = firestoreDoc(collection(db, 'conversion_metrics'), 'current');
    const userConversionRef = firestoreDoc(collection(db, 'user_conversion_metrics'), event.userId);
    const categoryRef = firestoreDoc(collection(db, 'category_metrics'), event.metadata.categoryId);

    // Atualiza métricas gerais de conversão
    await setDoc(conversionRef, {
      total: increment(1),
      revenueTotal: increment(event.metadata.revenue || 0)
    }, { merge: true });

    // Registra conversão por usuário
    await setDoc(userConversionRef, {
      conversions: increment(1),
      lastConversion: event.timestamp,
      totalSpent: increment(event.metadata.revenue || 0)
    }, { merge: true });

    // Atualiza métricas da categoria
    await setDoc(categoryRef, {
      conversions: increment(1),
      revenue: increment(event.metadata.revenue || 0)
    }, { merge: true });
  }

  async getDetailedMetrics(): Promise<DetailedMetrics> {
    try {
      const [
        generalMetrics,
        categoryMetrics,
        contentMetrics,
        userMetrics,
        conversionMetrics
      ] = await Promise.all([
        this.getGeneralMetrics(),
        this.getCategoryMetrics(),
        this.getContentMetrics(),
        this.getUserMetrics(),
        this.getConversionMetrics()
      ]);

      return {
        ...generalMetrics,
        categories: {
          ...generalMetrics.categories,
          categorySpecific: categoryMetrics
        },
        contents: {
          ...generalMetrics.contents,
          contentSpecific: contentMetrics
        },
        users: {
          ...generalMetrics.users,
          userSpecific: userMetrics
        },
        conversions: conversionMetrics
      };
    } catch (error) {
      console.error('Erro ao obter métricas detalhadas:', error);
      throw error;
    }
  }

  private getInitialMetrics(): DetailedMetrics {
    return {
      notifications: {
        views: 0,
        uniqueViews: 0,
        clicks: 0,
        uniqueClicks: 0,
        conversions: 0,
        conversionRate: 0
      },
      categories: {
        views: 0,
        uniqueViews: 0,
        clicks: 0,
        uniqueClicks: 0,
        conversions: 0,
        conversionRate: 0,
        premiumViews: 0,
        uniquePremiumViews: 0,
        premiumClicks: 0,
        uniquePremiumClicks: 0,
        premiumConversions: 0,
        premiumConversionRate: 0,
        categorySpecific: {}
      },
      contents: {
        views: 0,
        uniqueViews: 0,
        completions: 0,
        averageWatchTime: 0,
        contentSpecific: {}
      },
      users: {
        total: 0,
        active: 0,
        premium: 0,
        averageSessionTime: 0,
        averageWatchTime: 0,
        retentionRate: 0,
        userSpecific: {}
      },
      conversions: {
        total: 0,
        uniqueUsers: 0,
        conversionRate: 0,
        averageTimeToConversion: 0,
        revenueTotal: 0,
        revenuePer: 0,
        topConvertingCategories: [],
        topConvertingContents: []
      }
    };
  }

  // Métodos auxiliares para buscar métricas específicas
  private async getGeneralMetrics(): Promise<DetailedMetrics> {
    const metricsDocRef: DocumentReference = firestoreDoc(collection(db, 'analytics_metrics'), 'current');
    const metricsSnapshot: DocumentSnapshot<DocumentData> = await getDoc(metricsDocRef);
    return metricsSnapshot.exists() ? metricsSnapshot.data() as DetailedMetrics : this.getInitialMetrics();
  }

  private async getCategoryMetrics(): Promise<Record<string, CategoryMetrics>> {
    const snapshot = await getDocs(collection(db, 'category_metrics'));
    const metrics: Record<string, CategoryMetrics> = {};
    snapshot.forEach(docSnapshot => {
      metrics[docSnapshot.id] = docSnapshot.data() as CategoryMetrics;
    });
    return metrics;
  }

  private async getContentMetrics(): Promise<Record<string, ContentMetrics>> {
    const snapshot = await getDocs(collection(db, 'content_metrics'));
    const metrics: Record<string, ContentMetrics> = {};
    snapshot.forEach(docSnapshot => {
      metrics[docSnapshot.id] = docSnapshot.data() as ContentMetrics;
    });
    return metrics;
  }

  private async getUserMetrics(): Promise<Record<string, UserMetrics>> {
    const snapshot = await getDocs(collection(db, 'user_metrics'));
    const metrics: Record<string, UserMetrics> = {};
    snapshot.forEach(docSnapshot => {
      metrics[docSnapshot.id] = docSnapshot.data() as UserMetrics;
    });
    return metrics;
  }

  private async getConversionMetrics(): Promise<ConversionMetrics> {
    const conversionRef = firestoreDoc(collection(db, 'conversion_metrics'), 'current');
    const conversionSnapshot = await getDoc(conversionRef);
    return conversionSnapshot.exists() 
      ? conversionSnapshot.data() as ConversionMetrics 
      : this.getInitialMetrics().conversions;
  }
}

const analyticsService = new AnalyticsService();
export { analyticsService }; 