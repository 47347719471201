import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import AdminHome from "./pages/AdminHome";
import Users from "./pages/Users";
import Categories from "./pages/Categories";
import Contents from "./pages/Contents";
import Notifications from "./pages/admin/Notifications";
import Analytics from "./pages/admin/Analytics";
import Home from "./pages/Home";
import Register from "./pages/Register";
import UserCategories from "./pages/UserCategories";
import CategoryContents from "./pages/CategoryContents";
import User from "./pages/User";
import UserLayout from "./components/UserLayout";
import Navbar from "./components/Navbar";

const PrivateRoute: React.FC<{ children: React.ReactNode; requireAdmin?: boolean }> = ({ children, requireAdmin = false }) => {
  const { user, loading, isAdmin } = useAuth();

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (requireAdmin && !isAdmin) {
    return <Navigate to="/home" />;
  }

  return <>{children}</>;
};

const AppRoutes: React.FC = () => {
  const { user, isAdmin } = useAuth();

  // Redireciona para a página apropriada após o login
  const getInitialRoute = () => {
    if (!user) return '/login';
    return isAdmin ? '/admin/home' : '/home';
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* Rotas do painel administrativo */}
      <Route
        path="/admin"
        element={
          <PrivateRoute requireAdmin>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route path="home" element={<AdminHome />} />
        <Route path="users" element={<Users />} />
        <Route path="categories" element={<Categories />} />
        <Route path="contents" element={<Contents />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="analytics" element={<Analytics />} />
      </Route>

      {/* Rotas do usuário */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <UserLayout>
              <Navbar onMenuClick={() => {}} />
              <Outlet />
            </UserLayout>
          </PrivateRoute>
        }
      >
        <Route path="home" element={<Home />} />
        <Route path="categories" element={<UserCategories />} />
        <Route path="categories/:categoryId" element={<CategoryContents />} />
        <Route path="user" element={<User />} />
      </Route>

      {/* Redirecionamento da rota raiz */}
      <Route index element={<Navigate to={getInitialRoute()} replace />} />

      {/* Rota 404 - Redireciona para home ou admin dependendo do tipo de usuário */}
      <Route path="*" element={<Navigate to={getInitialRoute()} replace />} />
    </Routes>
  );
};

export default AppRoutes; 