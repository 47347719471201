import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getAnalytics, Analytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDq0mo1SlGmGJyp635UFOcYPBOZZx2MVBQ",
  authDomain: "metyflix-df965.firebaseapp.com",
  projectId: "metyflix-df965",
  storageBucket: "metyflix-df965.firebasestorage.app",
  messagingSenderId: "853903259528",
  appId: "1:853903259528:web:bd7b54adb35798eff36c3d",
  measurementId: "G-TESKYQ0WS7"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);

// Inicializa o Analytics apenas se for suportado
let analytics: Analytics | null = null;

const initAnalytics = async () => {
  try {
    if (await isSupported()) {
      analytics = getAnalytics(app);
      console.log('Firebase Analytics inicializado com sucesso');
    } else {
      console.warn('Firebase Analytics não é suportado neste ambiente');
    }
  } catch (error) {
    console.warn('Erro ao inicializar Firebase Analytics:', error);
  }
};

initAnalytics();

export { analytics };
export default app; 