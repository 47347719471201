import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardMedia,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
} from '@mui/icons-material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase';
import type { Category, Content } from '../types';
import Navbar from '../components/Navbar';
import { HeroBanner } from '../components/HeroBanner';
import ContentCarousel from '../components/ContentCarousel';

interface User {
  id: string;
  email: string;
  name: string;
  role: 'admin' | 'user';
  categoryAccess: string[];
  createdAt: Date;
  premiumCategories?: string[];
  avatarUrl?: string;
}

const Home: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [contents, setContents] = useState<{ [key: string]: Content[] }>({});
  const [userPremiumCategories, setUserPremiumCategories] = useState<string[]>([]);
  const [playingContent, setPlayingContent] = useState<Content | null>(null);
  const [infoContent, setInfoContent] = useState<Content | null>(null);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    fetchUserData();
    fetchCategories();
    fetchUsers();
  }, []);

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDocs(query(collection(db, 'users'), where('email', '==', user.email)));
      if (!userDoc.empty) {
        const userData = userDoc.docs[0].data();
        setUserPremiumCategories(userData.premiumCategories || []);
      }
    }
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Category[];
    
    setCategories(categoriesData.sort((a, b) => a.order - b.order));
    
    // Buscar conteúdos para cada categoria
    const contentsMap: { [key: string]: Content[] } = {};
    for (const category of categoriesData) {
      const contentsSnapshot = await getDocs(
        query(collection(db, 'contents'), where('categoryId', '==', category.id))
      );
      contentsMap[category.id] = contentsSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() } as Content))
        .sort((a, b) => a.order - b.order);
    }
    setContents(contentsMap);
  };

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const usersData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as User[];
    setUsers(usersData);
  };

  const handlePlay = () => {
    // Implementar lógica de reprodução
    console.log('Play clicked');
  };

  const handleInfo = (content: Content) => {
    setInfoContent(content);
  };

  const canAccessContent = (categoryId: string) => {
    const category = categories.find(c => c.id === categoryId);
    if (!category) return true;
    if (!category.isPremium) return true;
    
    const user = auth.currentUser;
    if (!user) return false;

    // Busca o usuário atual nos dados carregados
    const currentUser = users.find((u: User) => u.email === user.email);
    if (!currentUser) return false;

    // Verifica se o usuário tem acesso premium à categoria
    return currentUser.premiumCategories?.includes(categoryId) || false;
  };

  const handleMoreInfo = () => {
    // Implementar lógica de mais informações
    console.log('More info clicked');
  };

  const handleContentClick = (content: Content, category: Category) => {
    // Se é premium e o usuário NÃO tem acesso, redireciona para a URL da categoria
    if (category.isPremium && !userPremiumCategories.includes(category.id) && category.redirectUrl) {
      window.location.href = category.redirectUrl;
      return;
    }
    
    // Se tem acesso ou não é premium, reproduz o conteúdo
    setPlayingContent(content);
  };

  // Ajustando o caminho do banner para acessar diretamente da pasta public
  const defaultBanner = {
    title: "O conteúdo +18 mais VIP do Brasil",
    description: "Explore os destaques de hoje e mergulhe nos conteúdos mais quentes da Metyflix.",
    imageUrl: './banner-hero.jpg' // Ajustando o caminho para ser relativo
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: '#141414',
      color: 'white',
      position: 'relative', // Garante que o z-index funcione corretamente
    }}>
      <Navbar onMenuClick={() => {}} />
      <Box sx={{ 
        position: 'relative',
        zIndex: 0, // Garante que fique abaixo do navbar
      }}>
        <HeroBanner
          title={defaultBanner.title}
          description={defaultBanner.description}
          imageUrl={defaultBanner.imageUrl}
          onPlay={handlePlay}
          onMoreInfo={handleMoreInfo}
        />
      </Box>
      {/* Conteúdos por Categoria */}
      <Container 
        maxWidth={false} 
        sx={{ 
          mt: 4,
          position: 'relative',
          zIndex: 1,
          px: { xs: 2, sm: 4 },
        }}
      >
        {categories.map((category) => {
          const categoryContents = contents[category.id] || [];
          if (categoryContents.length === 0) return null;

          return (
            <Box key={category.id} sx={{ mb: 4 }}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                {category.name}
                {category.isPremium && !userPremiumCategories.includes(category.id) && (
                  <Typography
                    component="span"
                    sx={{ ml: 1, color: 'gold', fontSize: '0.8em' }}
                  >
                    Premium
                  </Typography>
                )}
              </Typography>
              <ContentCarousel
                category={category}
                contents={categoryContents}
                onContentClick={handleContentClick}
                onInfoClick={handleInfo}
                isPremium={category.isPremium}
                hasAccess={userPremiumCategories.includes(category.id)}
              />
            </Box>
          );
        })}
      </Container>

      {/* Modal de Reprodução */}
      <Dialog
        open={!!playingContent}
        onClose={() => setPlayingContent(null)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            bgcolor: '#141414',
            color: 'white',
          },
        }}
      >
        <DialogContent sx={{ p: 0, position: 'relative' }}>
          {playingContent && (
            <>
              {(playingContent.contentType === 'video' || (!playingContent.contentType && playingContent.videoUrl)) && (
                <Box sx={{ position: 'relative' }}>
                  <video
                    key={playingContent.fileUrl || playingContent.videoUrl || playingContent.contentUrl}
                    controls
                    autoPlay
                    style={{
                      width: '100%',
                      height: '500px',
                      backgroundColor: '#000',
                    }}
                  >
                    <source 
                      src={playingContent.fileUrl || playingContent.videoUrl || playingContent.contentUrl} 
                      type="video/mp4" 
                    />
                    Seu navegador não suporta a reprodução de vídeos.
                  </video>
                  <IconButton
                    onClick={(e) => {
                      const videoElement = e.currentTarget.parentElement?.querySelector('video');
                      if (videoElement) {
                        if (document.fullscreenElement) {
                          document.exitFullscreen();
                        } else {
                          videoElement.requestFullscreen();
                        }
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>
              )}
              {playingContent.contentType === 'image' && (
                <Box
                  sx={{
                    width: '100%',
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#000',
                    position: 'relative',
                  }}
                >
                  <img
                    src={playingContent.contentUrl || playingContent.fileUrl || '/placeholder-image.jpg'}
                    alt={playingContent.title}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      const imgElement = e.currentTarget.parentElement?.querySelector('img');
                      if (imgElement) {
                        if (document.fullscreenElement) {
                          document.exitFullscreen();
                        } else {
                          imgElement.requestFullscreen();
                        }
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>
              )}
              {playingContent.contentType === 'pdf' && (
                <Box sx={{ position: 'relative' }}>
                  <Box
                    component="iframe"
                    src={playingContent.contentUrl || playingContent.fileUrl || ''}
                    sx={{
                      width: '100%',
                      height: '500px',
                      border: 'none',
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      const iframeElement = e.currentTarget.parentElement?.querySelector('iframe');
                      if (iframeElement) {
                        if (document.fullscreenElement) {
                          document.exitFullscreen();
                        } else {
                          iframeElement.requestFullscreen();
                        }
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ bgcolor: '#141414' }}>
          <Button 
            onClick={() => setPlayingContent(null)}
            sx={{ color: 'white' }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de Informações */}
      <Dialog
        open={!!infoContent}
        onClose={() => setInfoContent(null)}
        maxWidth="sm"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            bgcolor: '#141414',
            color: 'white',
          },
        }}
      >
        {infoContent && (
          <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {infoContent.title}
              <IconButton
                onClick={() => setInfoContent(null)}
                sx={{ color: 'white' }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box
                component="img"
                src={infoContent.thumbnailUrl || '/placeholder-image.jpg'}
                alt={infoContent.title}
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 1,
                  mb: 2,
                }}
              />
              <Typography>{infoContent.description}</Typography>
              <Typography variant="caption" sx={{ display: 'block', mt: 2, color: 'rgba(255,255,255,0.7)' }}>
                Tipo: {infoContent.contentType === 'video' ? 'Vídeo' : infoContent.contentType === 'image' ? 'Imagem' : 'PDF'}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                startIcon={<PlayArrowIcon />}
                onClick={() => {
                  setInfoContent(null);
                  setPlayingContent(infoContent);
                }}
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  '&:hover': { bgcolor: 'rgba(255,255,255,0.75)' },
                }}
              >
                {infoContent.contentType === 'video' ? 'Assistir' : 'Visualizar'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}

export default Home; 