import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Grid,
  Card,
  CardMedia,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  PlayArrow as PlayArrowIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import type { Content, Category } from '../types';

interface ContentCarouselProps {
  category: Category;
  contents: Content[];
  onContentClick: (content: Content, category: Category) => void;
  onInfoClick: (content: Content) => void;
  isPremium?: boolean;
  hasAccess?: boolean;
}

const ContentCarousel: React.FC<ContentCarouselProps> = ({
  category,
  contents,
  onContentClick,
  onInfoClick,
  isPremium,
  hasAccess = false,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  // Número de itens visíveis baseado no tamanho da tela
  const itemsPerPage = isSmall ? 2 : isMedium ? 4 : 6;
  
  const handlePrevious = () => {
    setStartIndex((prev) => {
      const newIndex = prev - itemsPerPage;
      return newIndex < 0 ? Math.max(contents.length - itemsPerPage, 0) : newIndex;
    });
  };

  const handleNext = () => {
    setStartIndex((prev) => {
      const newIndex = prev + itemsPerPage;
      return newIndex >= contents.length ? 0 : newIndex;
    });
  };

  const handleContentAction = (content: Content, action: 'play' | 'info') => {
    // Se é premium e o usuário NÃO tem acesso, redireciona para a URL da categoria
    if (isPremium && !hasAccess && category.redirectUrl) {
      window.location.href = category.redirectUrl;
      return;
    }
    
    // Se tem acesso ou não é premium, executa a ação normal
    if (action === 'play') {
      onContentClick(content, category);
    } else {
      onInfoClick(content);
    }
  };

  const visibleContents = contents.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      {contents.length > itemsPerPage && (
        <>
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              left: -20,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0,0,0,0.5)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: -20,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0,0,0,0.5)',
              color: 'white',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          flexWrap: 'nowrap',
          transform: 'translateX(0)',
          transition: 'transform 0.5s ease-in-out',
        }}
      >
        {visibleContents.map((content) => (
          <Grid item key={content.id} xs={6} sm={3} md={2}>
            <Card
              sx={{
                position: 'relative',
                '&:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 0.3s ease-in-out',
                  zIndex: 1,
                  '& .content-overlay': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                {content.thumbnailUrl ? (
                  <CardMedia
                    component="img"
                    image={content.thumbnailUrl}
                    alt={content.title}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                ) : content.contentType === 'video' ? (
                  <video
                    src={content.fileUrl}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    preload="metadata"
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={'/placeholder-video.jpg'}
                    alt={content.title}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </Box>
              <Box
                className="content-overlay"
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  bgcolor: 'rgba(0,0,0,0.7)',
                  p: 1,
                  opacity: 0,
                  transition: 'opacity 0.3s ease-in-out',
                }}
              >
                <Typography variant="subtitle2" noWrap>
                  {content.title}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <IconButton
                    size="small"
                    onClick={() => handleContentAction(content, 'play')}
                    sx={{ color: 'white' }}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleContentAction(content, 'info')}
                    sx={{ color: 'white' }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Box>
              </Box>
              {isPremium && !hasAccess && (
                <Typography
                  variant="caption"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    bgcolor: 'gold',
                    color: 'black',
                    px: 1,
                    borderRadius: 1,
                  }}
                >
                  Premium
                </Typography>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ContentCarousel; 