import React, { useState, useEffect } from "react";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Notifications } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-hot-toast";
import { notificationsService } from '../services/notifications';
import { Notification } from '../types';
import { useNavigate } from 'react-router-dom';
import { analyticsService } from '../services/analytics';
import { onSnapshot, query, collection, where, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

const NotificationBell: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    
    // Primeiro, buscar todas as notificações ativas
    const activeQuery = query(
      collection(db, 'notifications'),
      where('isActive', '==', true)
    );

    const unsubscribe = onSnapshot(activeQuery, (snapshot) => {
      try {
        const activeNotifications = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Notification[];

        // Ordenar manualmente por order
        const sortedNotifications = activeNotifications
          .sort((a, b) => (a.order || 0) - (b.order || 0));
        
        setNotifications(sortedNotifications);
        setLoading(false);
        
        if (sortedNotifications.length > notifications.length) {
          setHasNewNotifications(true);
        }
      } catch (error) {
        console.error("Erro ao processar notificações:", error);
        setLoading(false);
      }
    }, (error) => {
      console.error("Erro ao observar notificações:", error);
      toast.error("Erro ao carregar notificações");
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHasNewNotifications(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: Notification) => {
    try {
      if (notification.destinationUrl) {
        window.location.href = notification.destinationUrl;
      }
      handleClose();
    } catch (error) {
      console.error('Erro ao processar clique na notificação:', error);
      handleClose();
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleClick}
        sx={{ position: "relative" }}
      >
        <Badge badgeContent={notifications.length} color="error">
          <AnimatePresence>
            {hasNewNotifications && (
              <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: 1.2 }}
                exit={{ scale: 1 }}
                transition={{ repeat: Infinity, duration: 1 }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "50%",
                  border: "2px solid #f50057",
                }}
              />
            )}
          </AnimatePresence>
          <Notifications />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxHeight: 400,
            width: 400,
            bgcolor: '#141414',
            color: 'white',
            p: 2,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" component="div">
            Notificações
          </Typography>
        </Box>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress size={24} />
          </Box>
        ) : notifications.length === 0 ? (
          <MenuItem disabled>
            <Typography>Nenhuma notificação ativa</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                py: 1,
              }}
            >
              {notification.thumbnailUrl && (
                <Box
                  component="img"
                  src={notification.thumbnailUrl}
                  alt=""
                  sx={{
                    width: '100%',
                    height: 100,
                    objectFit: 'cover',
                    mt: 1,
                    mb: 1,
                  }}
                />
              )}
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default NotificationBell; 