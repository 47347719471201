import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  IconButton,
  Switch,
  FormControlLabel,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Container,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Lock as LockIcon,
  DragIndicator as DragIndicatorIcon
} from '@mui/icons-material';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import FileUpload from '../components/FileUpload';

interface Category {
  id: string;
  name: string;
  description: string;
  imageUrl?: string;
  isPremium?: boolean;
  redirectUrl?: string;
  order: number;
}

interface SortableItemProps {
  category: Category;
  onEdit: (category: Category) => void;
  onDelete: (id: string) => void;
}

function SortableItem({ category, onEdit, onDelete }: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: category.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      sx={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        '&:last-child': {
          borderBottom: 'none',
        },
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        pr: '120px',
        position: 'relative',
      }}
    >
      <Box {...attributes} {...listeners} sx={{ mr: 2, cursor: 'grab', color: 'white' }}>
        <DragIndicatorIcon />
      </Box>
      
      {category.imageUrl && (
        <Box sx={{ width: 60, height: 60, mr: 2, position: 'relative', flexShrink: 0 }}>
          <img
            src={category.imageUrl}
            alt={category.name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
          {category.isPremium && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                p: 0.5,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '0 4px 0 4px',
              }}
            >
              <LockIcon sx={{ fontSize: 16, color: '#9b753f' }} />
            </Box>
          )}
        </Box>
      )}
      
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
            {category.name}
            {category.isPremium && (
              <LockIcon sx={{ ml: 1, fontSize: 16, color: '#9b753f' }} />
            )}
          </Box>
        }
        secondary={
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'rgba(255, 255, 255, 0.7)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {category.description}
          </Typography>
        }
        sx={{
          mr: 2,
          flex: '1 1 auto',
          minWidth: 0,
        }}
      />
      
      <Box
        sx={{
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          gap: 1,
        }}
      >
        <IconButton onClick={() => onEdit(category)} sx={{ color: 'white' }}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(category.id)} sx={{ color: 'white' }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
}

export default function Categories() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [open, setOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    imageUrl: '',
    isPremium: false,
    redirectUrl: '',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      order: doc.data().order || 0,
    })) as Category[];
    
    // Ordenar categorias pela ordem
    const sortedCategories = categoriesData.sort((a, b) => a.order - b.order);
    setCategories(sortedCategories);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = categories.findIndex((cat) => cat.id === active.id);
    const newIndex = categories.findIndex((cat) => cat.id === over.id);

    const updatedItems = arrayMove(categories, oldIndex, newIndex).map(
      (item: Category, index: number) => ({
        ...item,
        order: index,
      })
    );

    setCategories(updatedItems);

    // Atualizar no Firestore
    const batch = writeBatch(db);
    updatedItems.forEach((category: Category) => {
      const categoryRef = doc(db, 'categories', category.id);
      batch.update(categoryRef, { order: category.order });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error('Erro ao atualizar ordem das categorias:', error);
      // Reverter para a ordem anterior em caso de erro
      fetchCategories();
    }
  };

  const handleOpen = (category?: Category) => {
    if (category) {
      setEditingCategory(category);
      setFormData({
        name: category.name,
        description: category.description,
        imageUrl: category.imageUrl || '',
        isPremium: category.isPremium || false,
        redirectUrl: category.redirectUrl || '',
      });
    } else {
      setEditingCategory(null);
      setFormData({
        name: '',
        description: '',
        imageUrl: '',
        isPremium: false,
        redirectUrl: '',
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCategory(null);
    setFormData({
      name: '',
      description: '',
      imageUrl: '',
      isPremium: false,
      redirectUrl: '',
    });
  };

  const handleSubmit = async () => {
    try {
      const categoryData = {
        name: formData.name,
        description: formData.description,
        imageUrl: formData.imageUrl,
        isPremium: formData.isPremium,
        ...(formData.isPremium && { redirectUrl: formData.redirectUrl }),
        order: editingCategory ? editingCategory.order : categories.length,
        createdAt: new Date(),
      };

      if (editingCategory) {
        await updateDoc(doc(db, 'categories', editingCategory.id), categoryData);
      } else {
        await addDoc(collection(db, 'categories'), categoryData);
      }
      handleClose();
      fetchCategories();
    } catch (error) {
      console.error('Erro ao salvar categoria:', error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'categories', id));
      fetchCategories();
    } catch (error) {
      console.error('Erro ao deletar categoria:', error);
    }
  };

  const handleImageUpload = (url: string) => {
    setFormData(prev => ({
      ...prev,
      imageUrl: url,
    }));
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      backgroundColor: '#141414',
      color: 'white'
    }}>
      <Container maxWidth="xl">
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4 
        }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Gerenciar Categorias
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => handleOpen()}
            sx={{
              backgroundColor: '#9b753f',
              '&:hover': {
                backgroundColor: '#7d5d32',
              }
            }}
          >
            Nova Categoria
          </Button>
        </Box>

        <Paper sx={{ 
          width: '100%', 
          mb: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          borderRadius: 2,
        }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={categories.map(cat => cat.id)}
              strategy={verticalListSortingStrategy}
            >
              <List>
                {categories.map((category) => (
                  <SortableItem
                    key={category.id}
                    category={category}
                    onEdit={handleOpen}
                    onDelete={handleDelete}
                  />
                ))}
              </List>
            </SortableContext>
          </DndContext>
        </Paper>

        <Dialog 
          open={open} 
          onClose={handleClose} 
          maxWidth="md" 
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: '#1f1f1f',
              color: 'white',
            }
          }}
        >
          <DialogTitle>
            {editingCategory ? 'Editar Categoria' : 'Nova Categoria'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nome"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />
            <TextField
              margin="dense"
              label="Descrição"
              fullWidth
              multiline
              rows={4}
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />

            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Imagem da Categoria
              </Typography>
              {formData.imageUrl && (
                <Box sx={{ mt: 1, mb: 2 }}>
                  <img
                    src={formData.imageUrl}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
                  />
                </Box>
              )}
              <FileUpload
                folder="categories"
                onUploadComplete={handleImageUpload}
                accept="image/*"
                maxSize={2 * 1024 * 1024} // 2MB
              />
            </Box>

            <Box sx={{ mt: 3 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.isPremium}
                    onChange={(e) => setFormData({ ...formData, isPremium: e.target.checked })}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#9b753f',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#9b753f',
                      },
                    }}
                  />
                }
                label="Categoria Premium"
              />

              <Collapse in={formData.isPremium}>
                <TextField
                  margin="dense"
                  label="URL de Redirecionamento"
                  fullWidth
                  value={formData.redirectUrl}
                  onChange={(e) => setFormData({ ...formData, redirectUrl: e.target.value })}
                  helperText="URL para onde os usuários sem acesso serão redirecionados"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: 'white',
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.23)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'rgba(255, 255, 255, 0.7)',
                    },
                    '& .MuiFormHelperText-root': {
                      color: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                />
              </Collapse>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button 
              onClick={handleClose}
              sx={{ color: 'white' }}
            >
              Cancelar
            </Button>
            <Button 
              onClick={handleSubmit} 
              variant="contained"
              disabled={formData.isPremium && !formData.redirectUrl}
              sx={{
                backgroundColor: '#9b753f',
                '&:hover': {
                  backgroundColor: '#7d5d32',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(155, 117, 63, 0.5)',
                },
              }}
            >
              {editingCategory ? 'Salvar' : 'Criar'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
} 