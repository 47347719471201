import React, { useState } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Função para validar formato do email
  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Função para validar formato do telefone (aceita apenas DDD + 9 dígitos)
  const isValidPhone = (phone: string) => {
    // Remove todos os caracteres não numéricos
    const numbers = phone.replace(/\D/g, '');
    // Verifica se tem 11 dígitos (2 do DDD + 9 do número)
    if (numbers.length !== 11) return false;
    // Verifica se o DDD começa com dígito válido
    if (!numbers.match(/^[1-9][1-9]/)) return false;
    return true;
  };

  // Função para formatar o número de telefone enquanto digita
  const formatPhone = (value: string) => {
    const numbers = value.replace(/\D/g, '').slice(0, 11);
    if (numbers.length <= 2) {
      return numbers;
    }
    if (numbers.length <= 7) {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
    }
    return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7)}`;
  };

  // Função para verificar se email já existe
  const checkEmailExists = async (email: string) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  // Função para verificar se telefone já existe
  const checkPhoneExists = async (phone: string) => {
    const formattedPhone = `+55${phone.replace(/\D/g, '')}`;
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('phone', '==', formattedPhone));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Validações básicas
      if (!name.trim()) {
        throw new Error('Nome é obrigatório');
      }

      if (!isValidEmail(email)) {
        throw new Error('Email inválido');
      }

      const phoneNumbers = phone.replace(/\D/g, '');
      if (!isValidPhone(phoneNumbers)) {
        throw new Error('Telefone inválido. Digite o DDD e os 9 dígitos do número');
      }

      if (password.length < 6) {
        throw new Error('A senha deve ter pelo menos 6 caracteres');
      }

      // Criar usuário no Authentication primeiro
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      try {
        // Criar documento do usuário no Firestore
        const userData = {
          email,
          name,
          phone: `+55${phoneNumbers}`,
          role: 'user',
          categoryAccess: [],
          premiumCategories: [],
          createdAt: new Date().toISOString(),
          avatarUrl: '/avatars/avatar1.png',
          isBlocked: false
        };

        await setDoc(doc(db, 'users', userCredential.user.uid), userData);

        // Mostrar mensagem de sucesso e redirecionar
        setSuccess(true);
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 2000);
      } catch (firestoreError) {
        console.error('Erro ao criar documento no Firestore:', firestoreError);
        // Se houver erro ao criar o documento, deletar o usuário do Authentication
        await userCredential.user.delete();
        throw new Error('Erro ao criar perfil do usuário. Por favor, tente novamente.');
      }

    } catch (error: any) {
      console.error('Erro durante o cadastro:', error);
      let errorMessage = 'Ocorreu um erro. Tente novamente.';
      
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'Este email já está em uso.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Email inválido.';
      } else if (error.code === 'auth/operation-not-allowed') {
        errorMessage = 'Operação não permitida.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'A senha é muito fraca.';
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url('/bg.jpg')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              backdropFilter: 'blur(10px)',
              borderRadius: 2,
              width: '100%',
            }}
          >
            <Typography component="h1" variant="h4" sx={{ color: 'white', mb: 4, fontWeight: 'bold' }}>
              Criar Conta
            </Typography>

            {error && (
              <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Nome Completo"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                label="Telefone"
                value={phone}
                onChange={(e) => setPhone(formatPhone(e.target.value))}
                placeholder="(XX) XXXXX-XXXX"
                inputProps={{
                  maxLength: 15
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'white',
                    backgroundColor: '#333',
                    '& fieldset': { borderColor: '#333' },
                    '&:hover fieldset': { borderColor: '#666' },
                    '&.Mui-focused fieldset': { borderColor: '#666' },
                  },
                  '& .MuiInputLabel-root': { 
                    color: '#8c8c8c',
                    '&.Mui-focused': { color: '#8c8c8c' }
                  },
                }}
              />

              <Typography 
                align="center" 
                sx={{ 
                  color: 'white', 
                  my: 2,
                  fontSize: '1rem',
                  fontWeight: 'medium'
                }}
              >
                Esse é o último passo para conhecer o melhor da 🔥Metyflix🔥
              </Typography>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  height: '50px',
                  fontSize: '1.1rem',
                  bgcolor: '#e50914',
                  '&:hover': {
                    bgcolor: '#b2070e',
                  },
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': {
                      transform: 'scale(1)',
                      boxShadow: '0 0 0 0 rgba(229, 9, 20, 0.4)',
                    },
                    '70%': {
                      transform: 'scale(1.02)',
                      boxShadow: '0 0 0 10px rgba(229, 9, 20, 0)',
                    },
                    '100%': {
                      transform: 'scale(1)',
                      boxShadow: '0 0 0 0 rgba(229, 9, 20, 0)',
                    },
                  },
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'CRIAR CONTA'}
              </Button>

              <Snackbar
                open={success}
                autoHideDuration={2000}
                message="Conta criada com sucesso!"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                  sx: {
                    backgroundColor: '#4CAF50',
                    color: 'white',
                  }
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
} 