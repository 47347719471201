import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  Button,
  Container,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  Info as InfoIcon,
  ArrowBack as ArrowBackIcon,
  Fullscreen as FullscreenIcon,
} from '@mui/icons-material';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import type { Category, Content } from '../types';
import Navbar from '../components/Navbar';

export default function CategoryContents() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState<Category | null>(null);
  const [contents, setContents] = useState<Content[]>([]);
  const [playingContent, setPlayingContent] = useState<Content | null>(null);
  const [userPremiumCategories, setUserPremiumCategories] = useState<string[]>([]);

  useEffect(() => {
    fetchCategory();
    fetchContents();
    fetchUserData();
  }, [categoryId]);

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDoc = await getDocs(query(collection(db, 'users'), where('email', '==', user.email)));
      if (!userDoc.empty) {
        const userData = userDoc.docs[0].data();
        setUserPremiumCategories(userData.premiumCategories || []);
      }
    }
  };

  const fetchCategory = async () => {
    if (!categoryId) return;
    const categoryDoc = await getDoc(doc(db, 'categories', categoryId));
    if (categoryDoc.exists()) {
      setCategory({ id: categoryDoc.id, ...categoryDoc.data() } as Category);
    }
  };

  const fetchContents = async () => {
    if (!categoryId) return;
    const contentsSnapshot = await getDocs(
      query(collection(db, 'contents'), where('categoryId', '==', categoryId))
    );
    const contentsData = contentsSnapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() } as Content))
      .sort((a, b) => a.order - b.order);
    setContents(contentsData);
  };

  const canAccessContent = () => {
    if (!category) return true;
    if (!category.isPremium) return true;
    return userPremiumCategories.includes(categoryId || '');
  };

  const handleContentClick = (content: Content) => {
    if (category?.isPremium && !canAccessContent()) {
      if (category.redirectUrl) {
        window.location.href = category.redirectUrl;
      }
      return;
    }
    setPlayingContent(content);
  };

  if (!category) return null;

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#141414', color: 'white' }}>
      <Navbar onMenuClick={() => {}} />
      <Container maxWidth="xl" sx={{ pt: { xs: 8, sm: 10 }, pb: 8 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, mt: { xs: 2, sm: 0 } }}>
          <IconButton 
            onClick={() => navigate('/categories')}
            sx={{ color: 'white', mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1">
            {category.name}
            {category.isPremium && !canAccessContent() && (
              <Typography
                component="span"
                sx={{ ml: 1, color: 'gold', fontSize: '0.8em' }}
              >
                Premium
              </Typography>
            )}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {contents.map((content) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={content.id}>
              <Card
                onClick={() => handleContentClick(content)}
                sx={{
                  bgcolor: 'transparent',
                  transition: 'all 0.3s',
                  position: 'relative',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    zIndex: 1,
                    '& .content-actions': {
                      opacity: 1,
                    }
                  },
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    component="img"
                    image={content.thumbnailUrl || '/placeholder-image.jpg'}
                    alt={content.title}
                    sx={{
                      height: 200,
                      filter: !canAccessContent() ? 'brightness(0.3)' : 'none',
                    }}
                  />
                  {canAccessContent() && (
                    <Box
                      className="content-actions"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        p: 1,
                        background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 60%, transparent 100%)',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        startIcon={<PlayArrowIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPlayingContent(content);
                        }}
                        sx={{
                          bgcolor: 'white',
                          color: 'black',
                          '&:hover': { bgcolor: 'rgba(255,255,255,0.75)' },
                          minWidth: 0,
                          p: '4px 8px',
                        }}
                      >
                        <span translate="no">Play</span>
                      </Button>
                    </Box>
                  )}
                </Box>
                <Box 
                  sx={{ 
                    p: 1.5, 
                    bgcolor: '#1a1a1a',
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                  }}
                >
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: 'normal',
                      color: 'white',
                      textAlign: 'center',
                      fontSize: '0.9rem',
                      lineHeight: 1.2,
                    }}
                  >
                    {content.title}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Modal de Reprodução */}
      <Dialog
        open={!!playingContent}
        onClose={() => setPlayingContent(null)}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            bgcolor: '#141414',
            color: 'white',
          },
        }}
      >
        <DialogContent sx={{ p: 0, position: 'relative' }}>
          {playingContent && (
            <>
              {(playingContent.contentType === 'video' || (!playingContent.contentType && playingContent.videoUrl)) && (
                <Box sx={{ position: 'relative' }}>
                  <video
                    key={playingContent.fileUrl || playingContent.videoUrl || playingContent.contentUrl}
                    controls
                    autoPlay
                    style={{
                      width: '100%',
                      height: '500px',
                      backgroundColor: '#000',
                    }}
                  >
                    <source 
                      src={playingContent.fileUrl || playingContent.videoUrl || playingContent.contentUrl} 
                      type="video/mp4" 
                    />
                    Seu navegador não suporta a reprodução de vídeos.
                  </video>
                  <IconButton
                    onClick={(e) => {
                      const videoElement = e.currentTarget.parentElement?.querySelector('video');
                      if (videoElement) {
                        if (document.fullscreenElement) {
                          document.exitFullscreen();
                        } else {
                          videoElement.requestFullscreen();
                        }
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>
              )}
              {playingContent.contentType === 'image' && (
                <Box
                  sx={{
                    width: '100%',
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#000',
                    position: 'relative',
                  }}
                >
                  <img
                    src={playingContent.contentUrl || playingContent.fileUrl || '/placeholder-image.jpg'}
                    alt={playingContent.title}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      const imgElement = e.currentTarget.parentElement?.querySelector('img');
                      if (imgElement) {
                        if (document.fullscreenElement) {
                          document.exitFullscreen();
                        } else {
                          imgElement.requestFullscreen();
                        }
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>
              )}
              {playingContent.contentType === 'pdf' && (
                <Box sx={{ position: 'relative' }}>
                  <Box
                    component="iframe"
                    src={playingContent.contentUrl || playingContent.fileUrl || ''}
                    sx={{
                      width: '100%',
                      height: '500px',
                      border: 'none',
                    }}
                  />
                  <IconButton
                    onClick={(e) => {
                      const iframeElement = e.currentTarget.parentElement?.querySelector('iframe');
                      if (iframeElement) {
                        if (document.fullscreenElement) {
                          document.exitFullscreen();
                        } else {
                          iframeElement.requestFullscreen();
                        }
                      }
                    }}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'white',
                      bgcolor: 'rgba(0, 0, 0, 0.5)',
                      '&:hover': {
                        bgcolor: 'rgba(0, 0, 0, 0.7)',
                      },
                    }}
                  >
                    <FullscreenIcon />
                  </IconButton>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ bgcolor: '#141414' }}>
          <Button 
            onClick={() => setPlayingContent(null)}
            sx={{ color: 'white' }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
} 