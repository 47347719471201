import React from 'react';
import { Box } from '@mui/material';

interface UserLayoutProps {
  children: React.ReactNode;
}

export default function UserLayout({ children }: UserLayoutProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: '#141414' }}>
      {/* Conteúdo Principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#141414',
          minHeight: '100vh',
        }}
      >
        {children}
      </Box>
    </Box>
  );
} 