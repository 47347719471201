import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  LinearProgress,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';

interface FileUploadProps {
  onUploadComplete: (url: string) => void;
  folder: string;
  accept?: string;
  maxSize?: number; // em bytes
}

export default function FileUpload({ onUploadComplete, folder, accept = '*/*', maxSize = 100 * 1024 * 1024 }: FileUploadProps) {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Verificar tamanho do arquivo
    if (file.size > maxSize) {
      setError(`Arquivo muito grande. Tamanho máximo: ${Math.floor(maxSize / (1024 * 1024))}MB`);
      return;
    }

    setUploading(true);
    setError(null);

    try {
      // Criar nome único para o arquivo
      const fileName = `${Date.now()}-${file.name}`;
      const storageRef = ref(storage, `${folder}/${fileName}`);
      
      // Iniciar upload
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Monitorar progresso
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error('Erro no upload:', error);
          setError('Erro ao fazer upload do arquivo. Tente novamente.');
          setUploading(false);
        },
        async () => {
          // Upload completo
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onUploadComplete(downloadURL);
          setUploading(false);
          setProgress(0);
        }
      );
    } catch (error) {
      console.error('Erro ao iniciar upload:', error);
      setError('Erro ao iniciar upload. Tente novamente.');
      setUploading(false);
    }
  };

  return (
    <Box>
      <input
        type="file"
        accept={accept}
        onChange={handleFileSelect}
        style={{ display: 'none' }}
        id="file-upload"
        disabled={uploading}
      />
      <label htmlFor="file-upload">
        <Button
          variant="contained"
          component="span"
          startIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
          disabled={uploading}
          fullWidth
        >
          {uploading ? `Enviando... ${Math.round(progress)}%` : 'Selecionar Arquivo'}
        </Button>
      </label>

      {uploading && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
} 