import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { notificationsService } from '../../services/notifications';
import { toast } from 'react-hot-toast';
import { Notification } from '../../types';

interface CreateNotificationDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  editingNotification?: Notification | null;
}

export default function CreateNotificationDialog({
  open,
  onClose,
  onSuccess,
  editingNotification
}: CreateNotificationDialogProps) {
  const [title, setTitle] = useState(editingNotification?.title || '');
  const [destinationUrl, setDestinationUrl] = useState(editingNotification?.destinationUrl || '');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(editingNotification?.thumbnailUrl || null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      
      // Criar preview da imagem
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('title', title);
      
      if (destinationUrl) {
        formData.append('destinationUrl', destinationUrl);
      }
      
      if (selectedFile) {
        formData.append('imageFile', selectedFile);
      }

      if (editingNotification?.id) {
        await notificationsService.update(editingNotification.id, {
          title,
          destinationUrl: destinationUrl || null,
          thumbnailUrl: selectedFile ? undefined : (previewUrl || null)
        });
        toast.success('Notificação atualizada com sucesso!');
      } else {
        await notificationsService.create(formData);
        toast.success('Notificação criada com sucesso!');
      }
      
      onSuccess();
      handleClose();
    } catch (error) {
      console.error('Erro ao salvar notificação:', error);
      toast.error('Erro ao salvar notificação');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setTitle('');
    setDestinationUrl('');
    setSelectedFile(null);
    setPreviewUrl(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{editingNotification ? 'Editar Notificação' : 'Nova Notificação'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Título"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="URL de Destino (opcional)"
            type="url"
            fullWidth
            value={destinationUrl}
            onChange={(e) => setDestinationUrl(e.target.value)}
          />
          <Box mt={2}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="thumbnail-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="thumbnail-upload">
              <Button variant="outlined" component="span" fullWidth>
                {selectedFile ? 'Trocar Thumbnail' : 'Upload Thumbnail'}
              </Button>
            </label>
          </Box>
          {previewUrl && (
            <Box mt={2} textAlign="center">
              <img 
                src={previewUrl} 
                alt="Preview" 
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '200px', 
                  objectFit: 'cover',
                  borderRadius: '4px'
                }} 
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : editingNotification ? 'Salvar' : 'Criar'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
} 